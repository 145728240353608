import React from "react";
import styled from '@emotion/styled'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  FormControl,
  Select,
  Grid,
  FormHelperText,
  InputBase
} from "@mui/material";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const FormMain = styled(Form)(({ theme }) => {
  return {
    width: "85%",
    height: "60%",
    padding: "20px",
  };
});

const DialogTitleMain = styled(DialogTitle)(({ theme }) => {
  return {
    padding: "0px",
    marginBottom: "25px"
  };
});

const TypographyTitleMain = styled(Typography)(({ theme }) => {
  return {
    fontSize: "1.2rem",
    fontWeight: 600,
    color: theme.palette.surface.black,
  };
});

const GridSpace = styled(Grid)(({ theme }) => {
  return {
    padding: "0px",
    marginBottom: "25px",
  };
});

const SelectMain = styled(Select)(({ theme }) => {
  return {
    color: "black",
    padding: "0px",
    borderRadius: "0px !important",
  };
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase':{
    borderRadius: "0px !important"
  },
  '& .MuiInputBase-input': {
    color: "black",
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #ced4da',
    borderBottom: '1px solid #A5B0B8',
    borderRadius: "0px !important",
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    '&:focus': {
      border: '0px solid #ced4da',
      borderBottom: '1px solid #A5B0B8',
    },
  },
}));

const DialogTalentAllocation = ({
  resetModal,
  item,
  machine,
  listOper,
  assignOperator
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    resetModal();
  };

  return (
    <>
     <Grid item container justify="center">
      <Dialog open={open} maxWidth="md">
      <Formik
          validationSchema={Yup.object().shape({
            operatorId: Yup.number().required(t("Required")),
          })}
          initialValues={{
            operatorId: item,
            machineId: machine.id,
          }}
          onSubmit={({ machineId, operatorId}) => {
            assignOperator(machineId, operatorId);
            handleClose();
          }}
        >
           {({
            values,
            touched,
            errors,
            handleSubmit,
            handleBlur,
            setFieldValue,
            dirty,
          }) => (
            <FormMain onSubmit={handleSubmit}>
              <DialogTitleMain>
                <TypographyTitleMain>
                  {t("Talent to")} {machine.name}
                </TypographyTitleMain>
              </DialogTitleMain>
              <Grid container>
                <GridSpace item xs={12}>
                    <FormControl fullWidth>
                      <SelectMain
                        native
                        value={values.operatorId}
                        onChange={(evt) =>{
                          setFieldValue("operatorId", evt.target.value);
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          name: "operatorId",
                          id: "outlined-age-native-simple",
                        }}
                        classes={{
                          icon: {color: "black"}
                        }}
                        input={<BootstrapInput />}
                        error={touched.plantId && !!errors.plantId}
                      >
                        <option aria-label="None" value="" >{t("Operator Asignated")}</option>
                        {listOper.map((oper) => (
                          <option 
                            aria-label={oper.name === undefined ? 
                              oper.firstName + " " + oper.lastName :
                              oper.name
                            }
                            value={oper.id}>
                            {oper.name === undefined ? oper.firstName + " " + oper.lastName : oper.name}
                          </option>
                        ))}
                      </SelectMain>
                    </FormControl>
                    {touched.operatorId &&values.operatorId === "" && (
                    <FormHelperText error>{t("Required field")}</FormHelperText>
                  )}
                </GridSpace>
              </Grid>
              <Grid container direction="column">
                <DialogActions>
                  <Button
                    variant="contained"
                    onClick={resetModal}
                    style={{marginRight: "10px", background: "#788289", color: "black"}}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    disabled={!dirty}
                    onClick={handleSubmit}
                    onBlur={handleBlur}
                    variant="contained"
                    style={{backgroundColor: dirty ? "#336EF4" : "#788289"}}
                  >
                    {t("Accept")}
                  </Button>
                </DialogActions>
            </Grid>
            </FormMain>
          )}
        </Formik>
      </Dialog>
    </Grid>
    </>
  );
};



export default DialogTalentAllocation;