import React, { createContext, useContext, useEffect, useState } from "react";
import { gql } from "apollo-boost";
import { useQuery } from "@apollo/react-hooks";
import { Redirect } from "@gatsbyjs/reach-router";

export const AuthContext = createContext();
export const FactoryContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const ME_QUERY = gql`
  {
    me {
      id
      firstName
      lastName
      username
    }
  }
`;

export const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  const { setAuthTokens, authTokens } = useContext(AuthContext);
  const [userData, setUserData] = useState();

  const {
    error: userError,
    loading: userDataLoading,
    refetch,
  } = useQuery(ME_QUERY, {
    onError: () => {},
    onCompleted: (data) => {
      setUserData(data && data.me);
    },
  });

  useEffect(() => {
    if (
      authTokens &&
      !userError &&
      userData &&
      !userData.me &&
      !userDataLoading
    ) {
      refetch();
    }
  }, [authTokens, userError, userData, userDataLoading, refetch]);

  if (userError && !userDataLoading) {
    setAuthTokens();
    return <Redirect to="/login/" />;
  }

  return (
    <UserContext.Provider
      value={{
        userData,
        userDataLoading,
        userError,
        setUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
