import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/auth";
import { 
    AppBar, 
    Avatar,
    ClickAwayListener,
    Grow,
    Box,
    Button,
    IconButton,
    MenuList,
    MenuItem,
    Paper,
    Popper,
    SvgIcon,
    Toolbar, 
    Typography,
    Tooltip,
} from "@mui/material";
import logo from "../../images/logo-l.png";
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import NotificationPopOver from "../ui/NotificationPopOver";
import DashboardIcon  from "@mui/icons-material/Home";
import CapturerIcon from '@mui/icons-material/AlignHorizontalLeft';
import ConnectionsImg from "../../images/ERP.svg";

const MyAppBar = styled(AppBar)(({ theme }) => {
    return {
        direction: "row",
        background: "white",
        marginBottom: theme.spacing(10),
    }
});

const LinkHome = styled(Link)(({ theme }) => {
    return {
        marginRight: theme.spacing(3),
    }
});

const MySvgIcon = styled(SvgIcon)(({ theme }) => {
    return {
        marginTop: "3px",
        marginRight: theme.spacing(0.5),
        height: "20px",
        width: "20px",
        "@media screen and (min-width: 687px)": {
            height: "25px",
            width: "25px",
        },
    }
});

const MySvgImg = styled("img")(({ theme }) => {
    return {
        marginTop: "3px",
        marginRight: theme.spacing(0.5),
        height: "20px",
        width: "20px",
        "@media screen and (min-width: 687px)": {
            height: "25px",
            width: "25px",
        },
    }
});

const MyIconButton = styled(IconButton)(({ theme }) => {
    return {
        padding: "0px",
        "@media screen and (min-width: 1600px)": {
            padding: "0px",
        },
        marginBottom: "17px",
    }
});

const MyTextdisable = styled(Typography)(({ theme }) => {
    return {
        fontSize: "0.7rem",
        color: "#999999",
        lineHeight: "26px",
        letterSpacing: "0",
        paddingRight: "18px",
        textDecoration: "none",
        "@media screen and (min-width: 687px)": {
            fontSize: "1rem",
        },
    }
});

const MyText = styled(Typography)(({ theme }) => {
    return {
        fontSize: "0.7rem",
        color: "#9A9DA0",
        lineHeight: "26px",
        letterSpacing: "0",
        paddingRight: "18px",
        textDecoration: "none",
        "@media screen and (min-width: 687px)": {
            fontSize: "1rem",
        },
    }
});

const MyTextSelect = styled(Typography)(({ theme }) => {
    return {
        fontSize: "0.7rem",
        color: "#336EF4",
        lineHeight: "26px",
        letterSpacing: "0",
        paddingRight: "18px",
        textDecoration: "none",
        "@media screen and (min-width: 687px)": {
            fontSize: "1rem",
        },
    }
});

const AvatarUser = styled(Avatar)(({ theme }) => {
    return {
        alignSelf: "center",
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(1),
        letterSpacing: "0",
        "@media screen and (min-width: 687px)": {
            width: theme.spacing(5),
            height: theme.spacing(5),
        },
    }
});

const TextUser = styled(Typography)(({ theme }) => {
    return {
        fontSize: "0.7rem",
        color: theme.palette.surface.black,
        letterSpacing: "0",
        paddingLeft: "5 rem",
        alignSelf: "center",
        marginLeft: theme.spacing(0.5),
        "@media screen and (min-width: 687px)": {
            fontSize: "1rem",
        },
    }
});

const MyPopper = styled(Popper)(({ theme }) => {
    return {
        "& .MuiPaper-root": {
            backgroundColor: theme.palette.text.white,
        },
    }
});

const MyMenuItem = styled(MenuItem)(({ theme }) => {
    return {
        color: theme.palette.surface.black,
        "& .Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.black,
        },
    }
});

const MyPaper = styled(Paper)(({ theme }) => {
    return {
      width: "563px", 
      height: "260px",
      position: "absolute",
      left: "30%",
      top: "35%",
    };
  });
  
  const DivSpaceBetween = styled("div")(({ theme }) => {
    return {
      "display": "flex",
      "flex-wrap": "wrap",
      "justify-content": "space-between",
      "align-items": "unset",
      width: "100%",
    };
  });
  
  const Title = styled(Typography)(({ theme }) => {
    return {
      color: "#0E1630",
      fontSize: "24px",
      paddingLeft: "40px",
      paddingTop: "30px",
      paddingBottom: "40px",
    };
  });
  
  const MyButton = styled(Button)(({ theme }) => {
    return {
      justifyContent: "flex-start",
      height: "57px",
      fontSize: "16px",
      paddingLeft: "70px",
      "&:hover": {
        backgroundColor: theme.palette.surface.white,
      },
    };
  });

  const MyCloseButton = styled(IconButton)(({ theme }) => {
    return {
      paddingRight: "40px",
      "&:hover": {
        backgroundColor: theme.palette.surface.white,
      },
    };
  });

  const GrayBox = styled(Box)(({ theme }) => {
    return {
      height: "1px",
      width: "483px",
      marginLeft: "40px",
      backgroundColor: theme.palette.surface.fordGray,
    };
  });
  

const SuperiorMenu = () => {
    const { t } = useTranslation();
    const { userData } = useContext(UserContext);
    const [open, setOpen] = React.useState(false);
    const [showSupport, setShowSupport] = React.useState(false);
    const anchorRef = React.useRef(null);
    const {
        id,
        firstName,
        profile: { picture, notifications = [] } = {},
    } = userData || {};

    const pictureUrl = 
        picture && `${process.env.REACT_APP_STATIC_FILES_ADDRESS}${picture}`;

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleSupport = () => {
        setShowSupport(!showSupport);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    }

    const prevOpen = React.useRef(open);
    
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
          anchorRef.current.focus();
      } 
      prevOpen.current = open;
    }, [open]);

    if (!userData) {
        return null;
    }

    const NavLink = (props) => (
        <Link
            {...props}
            getProps={({ isPartiallyCurrent }) => {
                return {
                    style: {
                        opacity: isPartiallyCurrent ? "1" : "0.5",
                        color: "transparent",
                        marginRight: "18px",
                    },
                };
            }}
        />
    );

    const Dashboard = () => {
        const location = useLocation();
        return location.pathname.includes("dashboard") ? (
            <DashboardIcon style={{color: "#336EF4"}} />
        ) : (
            <DashboardIcon style={{color: "#9A9DA0"}} />
        );
    };

    const Capturer = () => {
        const location = useLocation();
        return location.pathname.includes("capturer") ? (
            <CapturerIcon style={{color: "#336EF4"}} />
        ) : (
            <CapturerIcon style={{color: "#9A9DA0"}} />
        );
    };

    const DashboardTxt = () => {
        const location = useLocation();
        return location.pathname.includes("dashboard") ? (
            <MyTextSelect>{t("Dashboard")}</MyTextSelect>
        ) : (
            <MyText>{t("Dashboard")}</MyText>
        );
    };

    const CapturerTxt = () => {
        const location = useLocation();
        return location.pathname.includes("capturer") ? (
            <MyTextSelect>{t("Capturer")}</MyTextSelect>
        ) : (
            <MyText>{t("Capturer")}</MyText>
        );
    };

    return (
        <MyAppBar position="fixed" >
            <Toolbar>
                <div style={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                    width: "100%",
                }}>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "start",
                        alignItems: "initial",
                    }}>
                         <LinkHome to="/">
                            <img style={{height: "29px"}} 
                                src={logo} alt="Inicio" />
                        </LinkHome>
                         
                        <NavLink to="/dashboard" style={{textDecoration: "none"}} >
                            <MySvgIcon>
                                <Dashboard />
                            </MySvgIcon>
                            <MyIconButton>
                                <DashboardTxt/>
                            </MyIconButton>
                        </NavLink>

                        <NavLink to="/capturer/stops" style={{textDecoration: "none"}}>
                            <MySvgIcon>
                                <Capturer />
                            </MySvgIcon>
                            <MyIconButton>
                                <CapturerTxt/>
                            </MyIconButton>
                        </NavLink>

                        <Tooltip title="Esta opción no esta disponible en esta versión.">
                            <span>
                                <NavLink to="/connections" style={{textDecoration: "none"}} onClick={event => event.preventDefault()}>
                                    <MySvgImg src={ConnectionsImg} style={{color: "#999999"}} />
                                    <MyIconButton>
                                        <MyTextdisable>
                                            {t("Connections")}
                                        </MyTextdisable>
                                    </MyIconButton>
                                </NavLink>
                            </span>
                        </Tooltip>
                        
                    </div>
                    <div style={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "start",
                        alignItems: "initial",
                    }}>
                        { <NotificationPopOver notifications={notifications} /> }
                        <IconButton
                            key={`user-${id}`}
                            ref={anchorRef}
                            onClick={handleToggle}
                        >
                            <AvatarUser src={pictureUrl}>
                                {`${firstName && firstName.charAt(0)}`}
                            </AvatarUser>
                            <TextUser>
                                {firstName && firstName.split(" ").slice(0,2).join(" ")}
                            </TextUser>
                            <MyPopper
                                open={open}
                                anchorEl={anchorRef.current}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin:
                                                placement === "bottom" ? "center top" : "center bottom",
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleClose}>
                                                <MenuList autoFocusItem={open} id="menu-list-grow">
                                                    <MyMenuItem
                                                        //component={Link}
                                                        onClick={handleSupport}
                                                        //to="/support"
                                                    >
                                                        {t("ContactToSupport")}
                                                    </MyMenuItem>
                                                    <MyMenuItem
                                                        component={Link}
                                                        onClick={handleClose}
                                                        to="/logout"
                                                    >
                                                        {t("Logout")}
                                                    </MyMenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </MyPopper>
                        </IconButton>
                    </div>
                    {showSupport && 
                        <div style={{
                            position: "fixed",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1
                        }}>
                            <MyPaper>
                                <DivSpaceBetween>
                                    <Title>
                                        {t("ContactToSupport")}
                                    </Title>
                                    <MyCloseButton onClick={handleSupport}>
                                        <CloseIcon style={{color: "#3D4247"}}/>
                                    </MyCloseButton>
                                </DivSpaceBetween>
                                <GrayBox />
                                <MyButton startIcon={<WhatsAppIcon style={{color: "#3D4247"}}/>}
                                    onClick={handleSupport}
                                    href='http://api.whatsapp.com/send?phone=528125401908'
                                    target="_blank"
                                    fullWidth
                                >
                                    WhatsApp
                                </MyButton>
                                <GrayBox />
                                <MyButton startIcon={<EmailIcon style={{color: "#3D4247"}}/>}
                                    onClick={handleSupport}
                                    href='mailto:support@example.com?subject=SendMail&body=Description' 
                                    target="_blank" 
                                    fullWidth
                                >
                                    Correo
                                </MyButton>
                                <GrayBox />
                            </MyPaper>
                        </div>
                    }
                </div>
            </Toolbar>
        </MyAppBar>
    );
};

export default SuperiorMenu;