import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import LoginFormMobile from "../Components/LoginFormMobile";
import LoginForm from "../Components/LoginForm";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { useAuth, UserContext } from "../context/auth";
import CircularProgress from '@mui/material/CircularProgress';
import { useMediaQuery } from "@mui/material";

const AUTHENTICATE = gql`
  mutation TokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      user {
        id
        username
        firstName
        lastName
      }
    }
  }
`;

const Login = (props) => {
  const { location } = props;
  let matches = useMediaQuery('(min-width:600px)');
  const [formError, setFormError] = useState(false);
  const { setUserData } = useContext(UserContext);
  const referrer =
    document.referrer.length && location.href !== `${location.origin}/login`
      ? document.referrer
      : `/dashboard`;

  const { setAuthTokens, authTokens } = useAuth();
  const [tokenAuth, { loading, data: responseData }] = useMutation(
    AUTHENTICATE,
    {
      onError: (error) => {
        setFormError(error);
      },
      onCompleted: (data) => {
        const { token, user } = data.tokenAuth;
        setFormError(false);
        setAuthTokens();
        setAuthTokens({ token });
        setUserData(user);
      },
    }
  );

  if (loading)
    return (
      <CircularProgress
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          bottom: "50%",
        }}
      />
    );

  if (!loading && !formError && responseData && authTokens) {
    return <Navigate to={referrer} noThrow />;
  }

  return (
    <>
    {
        matches &&
        <LoginForm submitForm={tokenAuth} error={formError} />
    }
    {
        !matches &&
        <LoginFormMobile submitForm={tokenAuth} error={formError} />
    }
    </>
  ) ;
};

export default Login;
