import React from "react";
import styled from '@emotion/styled'
import {
  Box,
  Typography,
} from "@mui/material";
import OperatorCardSelected from "./OperatorCardSelected";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const BoxContainer = styled(Box)(({ theme }) => {
  return {
    minHeight: "100px",
    border: "0.5px solid #979797",
    width: "100%",
    backgroundColor: theme.palette.surface.white,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "baseline",
    marginBottom: "10px",
  };
});

const TitleContainer = styled(Typography)(({ theme }) => {
  return {
    fontSize: "15px",
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: "bold",
    margin: "10px",
  };
});

const BoxTitle = styled(Box)(({ theme }) => {
  return {
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "baseline",
  };
});

const MyIcon = styled(AddCircleIcon)(({ theme }) => {
  return {
    fontSize: "1.8rem",
    fontWeight: "bold",
    color: theme.palette.surface.btn_blue,
    alignSelf: "center",
    marginRight: "12px",
  };
});

const DropZone = ({
  title, 
  listOper,
  handlerClose,
  handlerOpenDialog,
}) => {
  return (
    <BoxContainer>
        <BoxTitle>
          <TitleContainer>
            {title}
          </TitleContainer>
          <MyIcon onClick={() => handlerOpenDialog(null, {id: title, name: title})}/>
        </BoxTitle>
        {listOper.map((oper) => (
          <OperatorCardSelected key={oper.id} operator={oper} handleClick={handlerClose}/>
        ))}
      </BoxContainer>      
  );
};

export default DropZone;