import React from "react";
import NotificationDetailMobile from "../Components/NotificationDetail";

const NotificationDetail = () => {
    return (
    <>
      <NotificationDetailMobile/>
    </>
    );
  };
  
  export default NotificationDetail;