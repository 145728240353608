import React from "react";
import styled from '@emotion/styled'
import {
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import { useDrag } from "react-dnd";
import CloseIcon from '@mui/icons-material/Close';

const BoxOperator = styled(Box)(({ theme }) => {
  return {
    height: "44px",
    borderRadius: "22px",
    maxWidth: "max-content",
    opacity: "1",
    backgroundColor: "#336EF4",
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "flex-start",
    "align-items": "baseline",
    margin: "8px",
  };
});

const AvatarUser = styled(Avatar)(({ theme }) => {
  return {
      alignSelf: "center",
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: theme.spacing(1),
      marginLeft: "10px",
      letterSpacing: "0",
  }
});

const TextUser = styled(Typography)(({ theme }) => {
  return {
      fontSize: "0.85rem",
      color: theme.palette.surface.white,
      letterSpacing: "0",
      paddingLeft: "5 rem",
      alignSelf: "center",
      marginLeft: theme.spacing(0.5),
      marginRight: "12px",
  }
});

const MyIcon = styled(CloseIcon)(({ theme }) => {
  return {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.surface.white,
    alignSelf: "center",
    marginRight: "12px",
  }
});

const OperatorCardSelected = ({operator, handleClick}) => {
  const [ , drag] = useDrag(() => ({
    type: "Box",
    item: {id: operator.id},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const closeClick = () => {
    handleClick(operator.id);
  };

  return (
    <BoxOperator ref={drag}>
    <AvatarUser src={operator.pictureUrl}>
      {operator.name === undefined ? operator.firstName.charAt(0) : operator.name.charAt(0)}
    </AvatarUser>
    <TextUser>
      {operator.name === undefined ? operator.firstName + " " + operator.lastName : operator.name.split(" ").slice(0,2).join(" ")}
    </TextUser>
    <MyIcon onClick={closeClick}/>
  </BoxOperator>
  )
};

export default OperatorCardSelected;