import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";

let messaging = null;

if (window.location.protocol === "https:" || window.location.hostname.includes("localhost")) {

  var firebaseConfig = {
    apiKey: "AIzaSyDiSUiA8V3UuicfzGv12TBe9_mJvWZCVhs",
    authDomain: "flexbaze-lite.firebaseapp.com",
    projectId: "flexbaze-lite",
    storageBucket: "flexbaze-lite.appspot.com",
    messagingSenderId: "119995373551",
    appId: "1:119995373551:web:ae0704f140fef794bec81a",
    measurementId: "G-3YJ7KPZ186"
  };

  const firebaseApp = initializeApp(firebaseConfig);
  messaging = getMessaging(firebaseApp);
}

export const fetchToken = () => {
  if (window.location.protocol === "https:" || window.location.hostname.includes("localhost")) {
    return getToken(messaging, {vapidKey: 'BN9DLOhgBvimatfkMDOk7HzEUi_DVdrSAL9zFY-p3lg33EdFOZoQ1bgeupKg3IrPIYw_4htYzQMQZLurGor3Do0'}).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
      } else {
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }
}