import React from "react";
import NotificationsMobile from "../Components/NotificationsMobile";

const Notifications = () => {
    return (
    <>
      <NotificationsMobile/>
    </>
    );
  };
  
  export default Notifications;