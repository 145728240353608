const translations =  {
  en: {
    translation: {
      Absences: "Absences",
      Capture: "Capture",
      Capturer: "Capturer",
      Connections: "ERP Connections",
      ContactToSupport: "Contact to support",
      Dashboard: "Home",
      Disabled: "Disabled",
      Filter: "Filter",
      Filters: "Filters",
      ForgotPassword: "I forgot my password",
      Logout: "Logout",
      NoNewNotifications: "No new notifications",
      Password: "Password",
      "Sign in": "Sign in",
      Username: "Username",
      January: "Jan",
      February: "Feb",
      March: "Mar",
      April: "Abr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Aug",
      September: "Sep",
      October: "Oct",
      November: "Nov",
      December: "Dec",
      Machine: "Machine",
      Search: "Search",
      Machines: "Machines",
      Shift: "Shift",
      Operators: "Operators",
      Stops: "Stops",
      Talent: "Talent",
      Accept: "Accept",
      Cancel: "Cancel",
      "Operator Asignated": "Operator Asignated",
      "Description": "Description",
      Cause: "Cause",
      "End date and time":"Fecha y hora Final*",
      "Start date and time":"Fecha y hora Inicial*",
      "Capture Stop": "Capture stop",
      "Talent allocation": "Talent allocation",
      "Talent to": "Talent to ",
      "Required field": "Required field",
      "DarkMode": "Dark mode",
      VacationsPermissions: "Vacations/Permissions",
      Notifications: "Notifications",
      Delete: "Delete"
    },
  },
  es: {
    translation: {
      Absences: "Faltas",
      Capture: "Capturar",
      Capturer: "Capturador",
      Connections: "ERP Conexiones",
      ContactToSupport: "Contactar a soporte",
      Dashboard: "Home",
      Disabled: "Incapacitado",
      Filter: "Filtrar",
      Filters: "Filtros",
      ForgotPassword: "Olvidé mi contraseña",
      Logout: "Cerrar sesión",
      NoNewNotifications: "No hay notificaciones nuevas",
      Password: "Contraseña",
      "Sign in": "Iniciar sesión",
      Username: "Usuario",
      January: "Ene",
      February: "Feb",
      March: "Mar",
      April: "Abr",
      May: "May",
      June: "Jun",
      July: "Jul",
      August: "Ago",
      September: "Sep",
      October: "Oct",
      November: "Nov",
      December: "Dic",
      Machine: "Máquina",
      Search: "Buscar",
      Machines: "Máquinas",
      Shift: "Turno",
      Operators: "Operadores",
      Stops: "Paros",
      Talent: "Talento",
      Accept: "Aceptar",
      Cancel: "Cancelar",
      "Operator Asignated": "Operador asignado",
      "Description": "Descripción",
      Cause: "Causa",
      "End date and time":"Fecha y hora Final*",
      "Start date and time":"Fecha y hora Inicial*",
      "Capture Stop": "Capturar paro",
      "Talent allocation": "Asignación de talento",
      "Talent to": "Talento a ",
      "Required field": "Campo requerido",
      "DarkMode": "Modo oscuro",
      VacationsPermissions: "Vacaciones/Permisos",
      Notifications: "Notificaciones",
      Delete: "Eliminar"
    },
  },
};

export default translations;