import React from "react";
import styled from '@emotion/styled'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  FormControl,
  Select,
  Grid,
  FormHelperText,
  ThemeProvider,
  createTheme,
  InputBase
} from "@mui/material";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const FormMain = styled(Form)(({ theme }) => {
  return {
    width: theme.spacing(100),
    padding: "40px",
  };
});

const StyledButton = styled(Button)(({ theme }) => {
  return {
    fontSize: "14px",
    fontWeight: 600,
    height: "40px",
    marginBottom: "8px",
    borderRadius: "4px",
    textTransform: "none",
    justifyContent: "flex-center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.surface.btn_blue,
    "&:hover": {
      backgroundColor: theme.palette.surface.btn_blue,
    },
  };
});

const CancelButton = styled(Button)(({ theme }) => {
  return {
    fontSize: "14px",
    fontWeight: 600,
    height: "40px",
    marginBottom: "8px",
    borderRadius: "4px",
    opacity: 0.75,
    textTransform: "none",
    justifyContent: "flex-center",
    color: theme.palette.text.black,
    backgroundColor: theme.palette.surface.mediumGray,
    "&:hover": {
      backgroundColor: theme.palette.surface.mediumGray,
    },
  };
});

const DialogTitleMain = styled(DialogTitle)(({ theme }) => {
  return {
    padding: "0px",
    marginBottom: "25px"
  };
});

const TypographyTitleMain = styled(Typography)(({ theme }) => {
  return {
    fontSize: "1.7rem",
    color: theme.palette.surface.black,
    width: theme.spacing(45),
    marginLeft: "9px"
  };
});

const GridSpace = styled(Grid)(({ theme }) => {
  return {
    marginBottom: "25px",
  };
});

const SelectMain = styled(Select)(({ theme }) => {
  return {
    color: "black",
    borderRadius: "0px !important",
  };
});

const themePicker = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        day: {
          color: "#3D4247",
          backgroundColor: "#101630",
          borderRadius: "0px",
        },
        container: {
          backgroundColor: "#101630",
        },
        daySelected: {
          backgroundColor: "#336ef4",
          borderRadius: "50%",
          color: "#3D4247",
        },
        dayDisabled: {
          color: "#3D4247",
        },
        root: {
          color: "#3D4247",
          backgroundColor: "#FFFFFF",
          ".MuiPickersDay-root.Mui-selected": {
            backgroundColor: "#3D4247",
          },
        },
      }
    },
    MuiPopper:{
      styleOverrides: {
        root: {
          marginTop: "-54px !important",
        },
      }
    },
    MuiTextField:{
      styleOverrides: {
        root: {
          width: "100% !important",
          marginLeft: "5px !important",
          marginRight: "5px !important"
        },
      }
    },
    MuiSvgIcon:{
      styleOverrides: {
        root: {
          color: "#3D4247 !important",
        },
      }
    },
    MuiTypography:{
      styleOverrides: {
        root: {
          color: "#3D4247 !important",
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
        },
      }
    },
  },
});

const DateTimePickerMain = styled(DateTimePicker)(({ theme }) => {
  return {
    "& .MuiTextField-root":{
      border: '0px solid #ced4da',
      borderBottom: '1px solid #A5B0B8',
    },
    "& .MuiOutlinedInput-notchedOutline":{
      border: '0px solid #ced4da',
      borderBottom: '1px solid #A5B0B8',
    },
    width: "100% !important",
    marginLeft: "5px",
    marginRight: "5px"
  };
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase':{
    borderRadius: "0px !important"
  },
  '& .MuiInputBase-input': {
    color: "black",
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0px solid #ced4da',
    borderBottom: '1px solid #A5B0B8',
    borderRadius: "0px !important",
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    '&:focus': {
      border: '0px solid #ced4da',
      borderBottom: '1px solid #A5B0B8',
    },
  },
}));

const BootstrapTextField = styled(TextField)(({ theme }) => ({
  "& .MuiTextField-root":{
    border: '0px solid #ced4da',
    borderBottom: '1px solid #A5B0B8',
  },
  "& .MuiOutlinedInput-notchedOutline":{
    border: '0px solid #ced4da',
    borderRadius:"0px",
    borderBottom: '1px solid #A5B0B8',
  },
}));

const DialogAddStop = ({
  resetModal, listOper, listStopCauses, listMachines, listShifts
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    resetModal();
  };

  return (
    <>
     <LocalizationProvider dateAdapter={AdapterDateFns}>
     <Grid item container justify="center">
      <Dialog open={open} maxWidth="md">
      <Formik
          validationSchema={Yup.object().shape({
            causeId: Yup.number().required(t("Required")),
            machineId: Yup.number().required(t("Required")),
            shiftId: Yup.number().required(t("Required")),
            operatorId: Yup.number().required(t("Required")),
            description: Yup.string().required(t("Required")),
            startDate: Yup.date().required(t("Required")),
            endDate: Yup.date().required(t("Required")),
          })}
          initialValues={{
            causeId: 0,
            machineId: 0,
            shiftId: 0,
            operatorId: 0,
            description: "",
            startDate: new Date(),
            endDate: new Date(),
          }}
          onSubmit={({ causeId, machineId, shiftId, operatorId, description, startDate, endDate,}) => {
            handleClose();
          }}
        >
           {({
            values,
            touched,
            errors,
            handleSubmit,
            handleBlur,
            setFieldValue,
            handleChange,
            dirty,
          }) => (
            <FormMain onSubmit={handleSubmit}>
              <DialogTitleMain>
                <TypographyTitleMain>
                  {t("Capture Stop")}
                </TypographyTitleMain>
              </DialogTitleMain>
              <Grid container>
              
                <GridSpace item xs={6} style={{"padding-right": "5px"}}>
                <ThemeProvider theme={themePicker}>
                  <DateTimePickerMain
                    renderInput={(props) => <BootstrapTextField {...props} />}
                    label={t("Start date and time")}
                    value={values.startDate}
                    onChange={(newValue) => {
                    }}
                  />
                </ThemeProvider>
                </GridSpace>

                <GridSpace item xs={6}>
                <ThemeProvider theme={themePicker}>
                <DateTimePickerMain
                  renderInput={(props) => <BootstrapTextField {...props} />}
                  label={t("End date and time")}
                  value={values.endDate}
                  onChange={(newValue) => {
                  }}
                />
                </ThemeProvider>
                </GridSpace>

                <GridSpace item xs={12}>
                    <FormControl fullWidth>
                      <SelectMain
                        native
                        value={values.causeId}
                        onChange={(evt) =>{
                          setFieldValue("causeId", evt.target.value);
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          name: "causeId",
                          id: "outlined-age-native-simple",
                        }}
                        classes={{
                          icon: {color: "black"}
                        }}
                        input={<BootstrapInput />}
                        error={touched.causeId && !!errors.causeId}
                      >
                        <option aria-label="None" value="" >{t("Cause")}</option>
                        {listStopCauses.map((stopCause) => (
                          <option 
                            aria-label={stopCause.name}
                            value={stopCause.id}>
                            {stopCause.name}
                          </option>
                        ))}
                      </SelectMain>
                    </FormControl>
                    {touched.causeId &&values.causeId === "" && (
                    <FormHelperText error>{t("Required field")}</FormHelperText>
                  )}
                </GridSpace>

                <GridSpace item xs={12}>
                  <BootstrapInput fullWidth
                    InputProps={{
                      className: {
                        width: "100%",
                        color: "black",
                        backgroundColor: "white",
                      },
                    }}
                    onBlur={handleBlur}
                    placeholder={t("Description")}
                    label={t("Description")}
                    multiline
                    rowsMax={6}
                    rows={1}
                    onChange={handleChange}
                    name="description"
                    value={values.description}
                    error={touched.description && !!errors.description}
                    InputLabelProps={{
                      className: {
                        color: "black",
                        marginBottom: "20px",
                      },
                      classes: {
                        shrink: {
                          color: "black",
                          marginBottom: "20px",
                        },
                      },
                    }}
                  />
                  {touched.description && errors.description && (
                    <FormHelperText error>{t("Required field")}</FormHelperText>
                  )}
                </GridSpace>

                <GridSpace item xs={6} style={{"padding-right": "10px"}}>
                    <FormControl fullWidth>
                      <SelectMain
                        native
                        value={values.machineId}
                        onChange={(evt) =>{
                          setFieldValue("machineId", evt.target.value);
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          name: "machineId",
                          id: "outlined-age-native-simple",
                        }}
                        classes={{
                          icon: {color: "black"}
                        }}
                        input={<BootstrapInput />}
                        error={touched.machineId && !!errors.machineId}
                      >
                        <option aria-label="None" value="" >{t("Machine")}</option>
                        {listMachines.map((mac) => (
                          <option aria-label={mac.name} value={mac.id}>{mac.name}</option>
                        ))}
                      </SelectMain>
                    </FormControl>
                    {touched.machineId &&values.machineId === "" && (
                    <FormHelperText error>{t("Required field")}</FormHelperText>
                  )}
                </GridSpace>

                <GridSpace item xs={6} style={{"padding-left": "10px"}}>
                    <FormControl fullWidth>
                      <SelectMain
                        native
                        value={values.shiftId}
                        onChange={(evt) =>{
                          setFieldValue("shiftId", evt.target.value);
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          name: "shiftId",
                          id: "outlined-age-native-simple",
                        }}
                        classes={{
                          icon: {color: "black"}
                        }}
                        input={<BootstrapInput />}
                        error={touched.shiftId && !!errors.shiftId}
                      >
                        <option aria-label="None" value="" >{t("Shift")}</option>
                        {listShifts.map((shift) => (
                          <option 
                            aria-label={shift.name}
                            value={shift.id}>
                            {shift.name}
                          </option>
                        ))}
                      </SelectMain>
                    </FormControl>
                    {touched.shiftId &&values.shiftId === "" && (
                    <FormHelperText error>{t("Required field")}</FormHelperText>
                  )}
                </GridSpace>

                <GridSpace item xs={12}>
                    <FormControl fullWidth>
                      <SelectMain
                        native
                        value={values.operatorId}
                        onChange={(evt) =>{
                          setFieldValue("operatorId", evt.target.value);
                        }}
                        onBlur={handleBlur}
                        inputProps={{
                          name: "operatorId",
                          id: "outlined-age-native-simple",
                        }}
                        classes={{
                          icon: {color: "black"}
                        }}
                        input={<BootstrapInput />}
                        error={touched.operatorId && !!errors.operatorId}
                      >
                        <option aria-label="None" value="" >{t("Operator Asignated")}</option>
                        {listOper.map((oper) => (
                          <option 
                            aria-label={oper.name === undefined ? 
                              oper.firstName + " " + oper.lastName :
                              oper.name
                            }
                            value={oper.id}>
                            {oper.name === undefined ? oper.firstName + " " + oper.lastName : oper.name}
                          </option>
                        ))}
                      </SelectMain>
                    </FormControl>
                    {touched.operatorId &&values.operatorId === "" && (
                    <FormHelperText error>{t("Required field")}</FormHelperText>
                  )}
                </GridSpace>

              </Grid>
              <Grid container direction="column">
                <DialogActions>
                  <CancelButton
                    variant="contained"
                    onClick={resetModal}
                  >
                    {t("Cancel")}
                  </CancelButton>
                  <StyledButton
                    disabled={!dirty}
                    onClick={handleSubmit}
                    onBlur={handleBlur}
                    variant="contained"
                  >
                    {t("Accept")}
                  </StyledButton>
                </DialogActions>
            </Grid>
            </FormMain>
          )}
        </Formik>
      </Dialog>
    </Grid>
     </LocalizationProvider>
    
    </>
  );
};



export default DialogAddStop;