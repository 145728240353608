import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: ["Muli"],
  },
  palette: {
    primary: {
      main: "#023416",
      dark: "#03401b",
      bright: "#067933",
      dropDark: "#045a26",
    },
    surface: {
      white: "#ffffff",
      black: "#000000",
      lightGray: "#f9fbfc",
      softGray: "#c2cad1",
      mediumGray: "#788289",
      hardGray: "#3d4246",
      megaLightGray: "#F9F8F8",
      uberLightGray: "#D5D5D5",
      green: "#71bd4e",
      orange: "#e26716",
      fuchsia: "#a509d0",
      blue: "#2bb9f1",
      purple: "#5228ea",
      gold: "#ffbd00",
      darkGray: "#232323",
      fordGray: "#979797",
      btn_blue: "#336EF4",
      red: "#D2434A",
      greenPrimary:"#067934"
    },
    secondary: {
      main: "#19857b",
    },
    status: {
      success: "#00a889",
      warning: "#ea8800",
      danger: "#E26716",
      blue: "#336EF4",
      red: "#D2434A",
      backgroundBlue: "rgba(51,110,244,0.08)",
      yellow: "#EA8800",
      backgroundYellow: "rgba(234,136,0,0.08)",
      green: "#00A889",
      backgroundGreen: "rgba(0,168,137,0.08)",
      backgroundRed: "#FCF0F1",
      delivered: "#71BD4E",
      selectDay: "#336EF4",
      unselectDay: "#363D61",
      gray: "#A5B0B8",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: "#ffffff",
      secondary: "#A5B0B8",
      black: "#000000",
      white: "#ffffff",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#ffffff",
        },
      }
    },
    path: {
      styleOverrides: {
        stroke: "#336ef4",
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
        color: "#202850",
      },
      }
    },
    MuiListItem: {
      styleOverrides: {
        button: {
        "&:hover": {
          backgroundColor: "#336ef4 !important",
        },
        "&$selected": {
          "&:hover": {
            backgroundColor: "#336ef4",
          },
        },
      },
      }
    },
    MuiPickersDesktopDateRangeCalendar: {
      styleOverrides: {
        root: {
          background: "#202850",
        },
      }
    },
    MuiDateRangePicker: {
      styleOverrides: {
        "& .MuiPaper": {
          root: {
            background: "#202850",
          },
        },
        root: {
          background: "#202850",
          opacity: "-13",
          "& .MuiPaper": {
            root: {
              background: "#202850",
            },
          },
        },
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
        fontWeight: 600,
        textTransform: "capitalize"
      },
      containedPrimary: {
        backgroundColor: "#067934",
        fontWeight: 600,
      },
      }
    },
    // MuiSelect: {
    //   // icon: {
    //   //   color: "#FFFFFF",
    //   // },
    //   // outlined: {
    //   //   color: "#000000",
    //   //   backgroundColor: "#FFFFFF",
    //   //   "&:focus": {
    //   //     backgroundColor: "#f9fbfc",
    //   //   },
    //   // },
    // },
    MuiListItemText: {
      styleOverrides: {
        root: {
        flex: "0 0 auto",
        minWidth: 0,
        marginTop: "4px",
        marginBottom: "4px",
      },
      }
    },
    MuiListItemAvatar: {
      styleOverrides: {
        root: {
        minWidth: "42px",
        flexShrink: 0,
      },
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
        color: "#000000",
      },
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
        color: "#000000",
      },
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
        color: "#000000",
      },
      }
    },
    MuiPickersBasePicker: {
      styleOverrides: {
        pickerView: {
        backgroundColor: "#101630",
      },
      }
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
        color: "white",
        "&$selected": {
          color: "white",
        },
      },
      textColorSecondary: {
        color: "#336ef4",
        "&$selected": {
          color: "white",
        },
      },
      }
    },
    PrivateTabIndicator: {
      styleOverrides: {
        colorSecondary: {
        backgroundColor: "white",
      },
      }
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        dayLabel: {
        color: "white",
      },
      iconButton: {
        backgroundColor: "#101630",
      },
      }
    },
    Scrollbar: {},
    MuiPickersDay: {
      styleOverrides: {
        day: {
          color: "white",
          backgroundColor: "#101630",
          borderRadius: "0px",
        },
        container: {
          backgroundColor: "#101630",
        },
        daySelected: {
          backgroundColor: "#336ef4",
          borderRadius: "50%",
          color: "white",
        },
        dayDisabled: {
          color: "white",
        },
        root: {
          color: "white",
          backgroundColor: "#202850",
          ".MuiPickersDay-root.Mui-selected": {
            backgroundColor: "black",
          },
        },
      }
    },
  },
});

export default theme;