import React, { useState } from "react";
import TalentDesk from "../Components/Talent";
import CapturerMobile from "../Components/CapturerMobile";
import moment from "moment-timezone";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { GET_MACHINES, GET_USERS, GET_SHIFTS } from "./Dashboard.queries";
import { useQuery } from "@apollo/client";
import styled from '@emotion/styled';

let endDate = moment.utc();
let startDate = moment.utc().subtract(7, "days");
startDate.hour(0);
startDate.minute(0);
startDate.second(0);
startDate.millisecond(0);

const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const Talent = () => {

  const [SelectedItemMachine, setSelectedItemMachine] = useState();
  const [listMachines, setListMachines] = useState([]);
  const [listOperators, setListOperators] = useState([]);
  const [listShifts, setListShifts] = useState([]);

  const { loading: loadingMachines } = useQuery(GET_MACHINES, {
    onCompleted: (data) => {
      setListMachines(data.getOwnCompanies[0].machines.sort(function(a,b){
        return a.name.localeCompare(b.name);
      }));
    }
  });

  const { loading: loadingShifts } = useQuery(GET_SHIFTS, {
    onCompleted: (data) => {
      setListShifts(data.getShiftTypesByCompany.sort(function(a,b){
        return a.name.localeCompare(b.name);
      }));
    },
    variables: {"companyId": "1"}
  });

  const { loading: loadingOperators } = useQuery(GET_USERS, {
    onCompleted: (data) => {
      setListOperators(data.searchUsers.sort(function(a,b){
        return (a.firstName + " " + a.lastName).localeCompare((b.firstName + " " + b.lastName));
      }));
    },
    variables: {"input": ""}
  });

  const listMachinesAllocations = [
    {
      idMachine: 1,
      name: "Luis Ortega",
      subTitle: "Team Leader",
      pictureUrl: null,
      isSelected : false,
    },
    {
      idMachine: 4,
      name: "Claudia Garza",
      subTitle: "Team Leader",
      pictureUrl: null,
      isSelected : false,
    }
  ]

  const handleItemClickMachine = (event, item) => {
    if (SelectedItemMachine)
    {
      if (SelectedItemMachine.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemMachine();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemMachine(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemMachine(item);
    }
  };

  const [SelectedItemShift, setSelectedItemShift] = useState();

  const handleItemClickShift = (event, item) => {
    if (SelectedItemShift)
    {
      if (SelectedItemShift.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemShift();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemShift(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemShift(item);
    }
  };

  const [SelectedItemOperator, setSelectedItemOperator] = useState();

  const handleItemClickOperator = (event, item) => {
    if (SelectedItemOperator)
    {
      if (SelectedItemOperator.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemOperator();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemOperator(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemOperator(item);
    }
  };

  const [SelectedItemStop, setSelectedItemStop] = useState();

  const chartDataMobile = {
    series: [20, 20, 11, 11, 6],
    options: {
      chart: { type: "donut" },
      legend: { show: false },
      labels: ["Sin Captura", "Herramientas", "Material", "Mantenimiento", "Otros"],
      dataLabels: { 
        enabled: true,
        formatter: function(val, { seriesIndex, w }) {
          return [Math.round(val) + "% (" + w.config.series[seriesIndex] + " Hrs.)",  w.config.labels[seriesIndex]]
        },
        style: {
          fontSize: "14px",
          colors: ["#3D4247", "#3D4247", "#3D4247", "#3D4247", "#3D4247"],
        },
        dropShadow: {
          enabled: false,
        },
      },
      tooltip: { 
        enabled: false,
      },
      fill: { colors: ["#EE5840", "#9E5CB9", "#66CCFF", "#41BFB4", "#FDAB3D"] },
      states: {
        hover: { filter: { type: "none" } },
        active: { filter: { type: "none" } }
      },
      stroke: { width: 2 },
      title: {
        text: "PREN-010",
        align: "left",
        margin: 0,
        offsetX: -10,
        style: {
          fontSize: "15px",
          color: "#3A3A3A",
          fontFamily: "Muli",
          fontWeight: 600,
        },
      },
      subtitle: {
        text: startDate.format("DD MMM YY") + " - " + endDate.format("DD MMM YY"),
        align: "left",
        margin: 0,
        offsetX: -10,
        offsetY: 20,
        style: {
          fontSize: "12px",
          color: "#3D4247",
          fontFamily: "Muli",
        },
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          dataLabels: {
            offset: 35,
            minAngleToShowLabel: 0
          },
          donut: {
            size: "70%",
            labels: {
              show: true,
              name: { show: true },
              value: {
                show: true,
                fontSize: "30px",
                fontFamily: "Muli",
              },
              total: {
                show: true,
                label: "Hrs. Totales",
                fontSize: "20px",
                fontFamily: "Muli",
                showAlways: true,
              }
            }
          }
        }
      }
    }
  };

  const Data = {
    series: [{
      data: [85, 100, 85, 95, 100, 80, 85, 100]
    }],
    options: {
      chart: { 
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          }
        },
        active: {
          filter: {
            type: "none",
          }
        }
      },
      title: {
        text: "Utilización de talento",
        margin: 20,
        style: {
          fontSize: "24px",
          color: "#3D4247",
          fontFamily: "Muli",
        }
      },
      colors: ["#336EF4"],
      plotOptions: {
        bar: {
          columnWidth: '60%',
          distributed: true,
        },
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: { 
        enabled: false,
      },
      legend: { show: false },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          formatter: (value) => value.toFixed(0) +'%',
        },
      },
      xaxis: {
        categories: ['Juan', 'Pepe', 'Miguel', 'Eduardo', 'José', 'Ricardo', 'Carlos', 'Adolfo'],
        labels: {
          style: {
            colors: ["#3D4247"],
            fontSize: '12px'
          }
        }
      } 
    }
  };

  const DataMobile = {
    series: [{
      data: [85, 100, 85, 95, 100, 80, 85, 100]
    }],
    options: {
      chart: { 
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          }
        },
        active: {
          filter: {
            type: "none",
          }
        }
      },
      title: {
        text: "Utilización de talento",
        align: "left",
        margin: 0,
        offsetX: -10,
        style: {
          fontSize: "15px",
          color: "#3D4247",
          fontFamily: "Muli",
          fontWeight: 600,
        }
      },
      subtitle: {
        text: startDate.format("DD MMM YY") + " - " + endDate.format("DD MMM YY"),
        align: "left",
        margin: 0,
        offsetX: -10,
        offsetY: 20,
        style: {
          fontSize: "12px",
          color: "#3D4247",
          fontFamily: "Muli",
        },
      },
      colors: ["#336EF4"],
      plotOptions: {
        bar: {
          columnWidth: '60%',
          distributed: true,
        },
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: { 
        enabled: false,
      },
      legend: { show: false },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          formatter: (value) => value.toFixed(0) +'%',
        },
      },
      xaxis: {
        categories: ['Juan', 'Pepe', 'Miguel', 'Eduardo', 'José', 'Ricardo', 'Carlos', 'Adolfo'],
        labels: {
          rotate: -30,
          rotateAlways: true,
          style: {
            colors: ["#3D4247"],
            fontSize: '12px'
          }
        }
      } 
    }
  };

  const listStops = [
  ]

  const handleItemClickStop = (event, item) => {
    if (SelectedItemStop)
    {
      if (SelectedItemStop.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemStop();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemStop(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemStop(item);
    }
  };

  const handleChangeDate = (date) => {
    startDate = moment(date[0]);
    startDate.hour(0);
    startDate.minute(0);
    startDate.second(0);
    startDate.millisecond(0);

    endDate = moment(date[1]);
    endDate.hour(23);
    endDate.minute(59);
    endDate.second(59);
  };

  const matches = useMediaQuery('(min-width:600px)');

  if (loadingMachines || loadingOperators || loadingShifts)
    return <LoadingProgress />;

  return (
    <>
      {
        matches && <TalentDesk 
          listMachinesAllocations ={listMachinesAllocations}
          listMachines={listMachines} 
          handleItemClickMachine = {handleItemClickMachine} 
          SelectedItemMachine={SelectedItemMachine} 
        
          listShift={listShifts} 
          handleItemClickShift = {handleItemClickShift} 
          SelectedItemShift={SelectedItemShift} 
        
          listOperators={listOperators} 
          handleItemClickOperator = {handleItemClickOperator} 
          SelectedItemOperator={SelectedItemOperator} 
        
          listStops={listStops} 
          handleItemClickStop = {handleItemClickStop} 
          SelectedItemStop={SelectedItemStop} 
        
          startDate={startDate.format("DD MMM YY")}
          endDate={endDate.format("DD MMM YY")}
          handleChangeDate={handleChangeDate}
          chartOptions={Data.options}
          chartSeries={Data.series}
        />
      }
      {
        !matches && <CapturerMobile
          listMachines={listMachines} 
          handleItemClickMachine = {handleItemClickMachine} 
          SelectedItemMachine={SelectedItemMachine} 
        
          listShift={listShifts} 
          handleItemClickShift = {handleItemClickShift} 
          SelectedItemShift={SelectedItemShift} 
        
          listOperators={listOperators} 
          handleItemClickOperator = {handleItemClickOperator} 
          SelectedItemOperator={SelectedItemOperator} 
        
          listStops={listStops} 
          handleItemClickStop = {handleItemClickStop} 
          SelectedItemStop={SelectedItemStop} 
        
          startDate={startDate.format("DD MMM YY")}
          endDate={endDate.format("DD MMM YY")}
          handleChangeDate={handleChangeDate}
          
          options={chartDataMobile.options}
          ser={chartDataMobile.series} 
          barOptions={DataMobile.options}
          barSeries={DataMobile.series}
          isStopSelected={false}
        />
      }
    </>
  )
}

export default Talent;