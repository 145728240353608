import React from "react";
import ButtonFilert  from "./ui/ButtonFilter";
import ButtonDateRange from "./ui/ButtonDatePickerRange";
import styled from '@emotion/styled';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from "@mui/material";
import { Chart as ChartJS, ArcElement, Tooltip as TooltipJS, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, TooltipJS, Legend);

const BoxMain = styled(Box)(({ theme }) => {
  return {
    height: "100vh",
    paddingTop: theme.spacing(2),
    paddingRight: "60px",
    paddingLeft: "60px",
  };
});

const DivSpaceBetween = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "start",
    "align-items": "baseline",
    marginTop: "35px"
  };
});

const GridCharts = styled(Grid)(({ theme }) => {
  return {
    display: "flex",
    "flex-wrap": "wrap",
    "justify-content": "start",
    "align-items": "flex-start",
    verticalAlign: "top",
    marginTop: "15px",
  };
});

const Title = styled(Typography)(({ theme }) => {
  return {
    fontSize: "21px",
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: 600,
    marginBottom: "130px",
    marginLeft: "20px"
  };
});

const Dashboard = ({
  listMachines,
  handleItemClickMachine,
  SelectedItemMachine,
  listShift,
  handleItemClickShift,
  SelectedItemShift,
  listOperators,
  handleItemClickOperator,
  SelectedItemOperator,
  listStops,
  handleItemClickStop,
  SelectedItemStop, 
  startDate,
  endDate,
  handleChangeDate,
  config,
  optionsBar,
  seriesBar,
}) => {

  const { t } = useTranslation();

  return (
    <BoxMain>
        <DivSpaceBetween>
          <ButtonDateRange 
          startDate ={startDate}
          endDate ={endDate}
          handleChangeDate = {handleChangeDate}
          />
          <ButtonFilert 
          list={listMachines} 
          handleItemClick = {handleItemClickMachine} 
          selectedItem={SelectedItemMachine} 
          searchView={true} 
          title={t("Machines")}
          />

          <ButtonFilert 
            list={listShift} 
            handleItemClick = {handleItemClickShift} 
            selectedItem={SelectedItemShift} 
            searchView={false} 
            title={t("Shift")}
            />

          <ButtonFilert 
            list={listOperators} 
            handleItemClick = {handleItemClickOperator} 
            selectedItem={SelectedItemOperator} 
            searchView={true} 
            title={t("Operators")}
            />

          <ButtonFilert 
            list={listStops} 
            handleItemClick = {handleItemClickStop} 
            selectedItem={SelectedItemStop} 
            searchView={false} 
            title={t("Stops")}
            />
        </DivSpaceBetween>
        <Card sx={{ width: "100%" , height: "700px", marginTop: "-25px"}}>
          <CardContent>
            <GridCharts container xs={12} spacing={1}>
              <Grid xs={5}>
              <Title>{t("PREN-010")}</Title>
              <Doughnut
                  data={config.data}
                  options={config.options}
                  plugins={config.plugins}
                  style={{position: "relative", height:"25vh", width:"50vw"}}
                />
              </Grid>
              <Grid xs={7}>
                <ReactApexChart
                  options={optionsBar}
                  series={seriesBar}
                  type="bar"
                  width={750}
                  height={620}
                />
              </Grid>
            </GridCharts>
          </CardContent>
        </Card>
        
    </BoxMain>
  );
};

export default Dashboard;