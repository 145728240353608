import React, { useState } from "react";
import ButtonFilert  from "./ui/ButtonFilter"
import ButtonDateRange from "./ui/ButtonDatePickerRange"
import styled from '@emotion/styled'
import {
  Box,
  Dialog,
  Grid,
  Card,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import DialogAddStop from "./ui/DialogAddStop";
import DialogTalentMobile from "./ui/DialogTalentMobile";
import ReactApexChart from "react-apexcharts";
import SubMenu from "./ui/SubMenu";
import MachineAllocation from "./ui/MachineAllocation";
//import TalentAllocationCard from "./ui/TalentAllocationCard"


const BoxMain = styled(Box)(({ theme }) => {
  return {
    height: "100vh",
    paddingTop: "52px",
    paddingRight: "60px",
    paddingLeft: "60px",
  };
});

const Title = styled(Typography)(({ theme }) => {
  return {
    fontSize: "24px",
    color: "#3A3A3A",
    fontFamily: "Muli",
    fontWeight: "bold",
    marginBottom: "15px",
  };
});

const DivSpaceBetween = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    alignItems: "baseline",
    marginTop: "35px",
    marginBottom: "0px",
  };
});

const CardMachines = styled(Card)(({ theme }) => {
  return {
    width: "90%",
    maxHeight: "525px",
    height: "525px",
    paddingTop: "44px",
    paddingRight: "44px",
    paddingLeft: "44px",
    paddingBottom: "28px",
    display: "flex",
    flexWrap: "wrap",
    "overflow-y": "scroll",
    justifyContent: "space-between",
    alignItems: "baseline",
  };
});

const GridCharts = styled(Grid)(({ theme }) => {
  return {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "start",
    alignItems: "flex-start",
    verticalAlign: "top",
    marginTop: "0px", 
  };
});

const options = [
  {
    id: "1",
    path: "/capturer/stops",
    text: "Stops",
  },
  {
    id: "2",
    path: "/capturer/talent",
    text: "Talent",
  },
];

const Talent = ({
  listMachines,
  handleItemClickMachine,
  SelectedItemMachine,
  listShift,
  handleItemClickShift,
  SelectedItemShift,
  listOperators,
  handleItemClickOperator,
  SelectedItemOperator,
  listStops,
  handleItemClickStop,
  SelectedItemStop, 
  startDate,
  endDate,
  handleChangeDate,
  chartOptions,
  chartSeries,
}) => {
  const [addDialogAddStopOpen, setDialogAddStopOpen] = useState(
    false
  );
  const [addDialogTalentAllocation, setDialogTalentAllocation] = useState(false);
  const { t } = useTranslation();
  const [machineAssigned, setMachineAssigned] = useState(null);
  const [talentAssigned, setTalentAssigned] = useState(null);
  let talentsAssigned = [];
  for (let i = 0; i < listMachines.length; i++)
  {
    talentsAssigned[i] = null;
  }
  const [talents, setTalents] = useState(talentsAssigned);

  const handlerOpenDialog =(item, machine) =>{
    setMachineAssigned(machine);
    setTalentAssigned(item);
    setDialogTalentAllocation(true)
  }

  const handleClick = (machineId) => {
    const machineSelected = listMachines.filter((machine) => String(machine.id) === String(machineId));
    const idxTalents= listMachines.indexOf(machineSelected[0]);
    if (idxTalents > -1)
    {
      const talentsAux = talents;
      talentsAux[idxTalents] = null;
      setTalents(talentsAux);
    }
  };

  const assignOperator = (machineId, operatorId) => {
    const machineSelected = listMachines.filter((machine) => String(machine.id) === String(machineId));
    const operatorSelected = listOperators.filter((oper) => String(oper.id) === String(operatorId));
    const idxTalents= listMachines.indexOf(machineSelected[0]);
    if (idxTalents > -1)
    {
      const talentsAux = talents;
      talentsAux[idxTalents] = operatorSelected[0];
      setTalents(talentsAux);
    }
  };

  return [
    <SubMenu optionsList={options} />,
    <BoxMain>
        <DivSpaceBetween>
          <ButtonDateRange 
          startDate ={startDate}
          endDate ={endDate}
          handleChangeDate = {handleChangeDate}
          />
          <ButtonFilert 
          list={listMachines} 
          handleItemClick = {handleItemClickMachine} 
          selectedItem={SelectedItemMachine} 
          searchView={true} 
          title={t("Machines")}
          />

          <ButtonFilert 
            list={listShift} 
            handleItemClick = {handleItemClickShift} 
            selectedItem={SelectedItemShift} 
            searchView={false} 
            title={t("Shift")}
            />

          <ButtonFilert 
            list={listOperators} 
            handleItemClick = {handleItemClickOperator} 
            selectedItem={SelectedItemOperator} 
            searchView={true} 
            title={t("Operators")}
            />
        </DivSpaceBetween>
        <GridCharts container xs={12} spacing={0}>
          <Grid xs={7}>
            <Card sx={{ width: "90%" , height: "600px"}}>
              <ReactApexChart
                options={chartOptions}
                series={chartSeries}
                type="bar"
                height={590}
              />
            </Card>
          </Grid>
          <Grid xs={5}>
          <CardMachines>
            <Title>{t("Talent allocation")}</Title>
            {listMachines.map((machine, idx) => (
              <MachineAllocation
                key={machine.id}
                machine={machine}
                operator={talents[idx]}
                handlerOpenDialog={handlerOpenDialog}
                handleClick={handleClick}
              />
            ))}
          </CardMachines>
            
          </Grid>
        </GridCharts>
        {
          <Dialog
            open={addDialogAddStopOpen}
            onClose={() => setDialogAddStopOpen(false)}
          >
            <DialogAddStop
              resetModal={() => {
                setDialogAddStopOpen(false);
              }}
              listOper={listOperators}
            />
          </Dialog>
        }
        {
          <Dialog
            open={addDialogTalentAllocation}
            onClose={() => setDialogTalentAllocation(false)}
          >
            <DialogTalentMobile
              resetModal={() => {
                setDialogTalentAllocation(false);
              }}
              machine= {machineAssigned}
              item = {talentAssigned}
              listOper={listOperators}
              assignOperator={assignOperator}
            />
          </Dialog>
        }
    </BoxMain>
  ];
};



export default Talent;