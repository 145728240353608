import React , {useState} from "react";
import styled from '@emotion/styled'
import {
  Button,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from "react-i18next";

const FilterBtn = styled(Button)(({ theme }) => {
  return {
    fontSize: "14px",
    fontWeight: 600,
    height: "40px",
    marginBottom: "8px",
    borderRadius: "4px",
    textTransform: "none",
    justifyContent: "flex-center",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.surface.btn_blue,
    "&:hover": {
      backgroundColor: theme.palette.surface.btn_blue,
    },
  };
});

const StyledList = styled(List)({
  color: "#336ef4",
  '& .MuiSvgIcon-root': {
    color: "#336ef4",
  },
  '& .MuiTypography-root': {
    color: "#336ef4",
  },

  // selected and (selected + hover) states
  '&& .Mui-selected, && .Mui-selected:hover': {
    backgroundColor: '#336ef4',
    '&, & .MuiSvgIcon-root, & .MuiTypography-root': {
      color: '#ffffff',
    },
  },
  // hover states
  '& .MuiListItemButton-root:hover': {
    backgroundColor: '#336ef4',
    '&, & .MuiSvgIcon-root, & .MuiTypography-root': {
      color: '#ffffff',
    },
  },
});


const GridList = styled(Grid)(({theme}) => {
  return {
    position: "absolute",
    "margin-left": "-320px",
    "margin-top": "50px!important",
    background: "white",
    paddingTop: "10px",
    paddingBottom: "0px",
    paddingLeft: "10px",
    paddingRight: "10px",
    overflow:"auto",
    overflowX: "hidden",
    maxHeight: "299px",
    border: "0.5px solid #979797 !important",
    borderRadius: "8px",
    display: 'inline-block',
    zIndex: 1,
    '&::-webkit-scrollbar': {
      width: '4px',
      paddingTop: "4px",
      borderRadius: "4px",
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: "4px",
      marginTop: "4px",
      marginBottom: "4px",
      backgroundColor: theme.palette.surface.white,
    },
    '&::-webkit-scrollbar-thumb': {
      paddingTop: "4px",
      borderRadius: "4px",
      backgroundColor: theme.palette.status.gray,
    },
  };
});

const DivSpaceBetween = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "nowrap",
    "justify-content": "space-between",
    "align-items": "unset",
    width: "100%",
    color: "#336ef4",
    "&:hover": {
      color: "#ffffff !important",
    }
  };
});

const StyledButton = styled(ListItemButton)({
  border: "1px solid #336ef4 !important",
  borderRadius: "4px",
  height: "50px !important",
  width: "220px !important",
  marginBottom: "10px",
  marginLeft: "0px",
  color: "#336ef4",
  backgroundColor: "white",
});

const MyTextField = styled("input")(({ theme }) => {
  return {
    "box-sizing": "border-box",
    height: "38px",
    width: "221px",
    border: "1px solid #336EF4",
    "border-radius": "8px",
    marginBottom: "2px",
    paddingLeft: "14px",
    "&:hover": {
      border: "1px solid #336EF4 !important",
      "border-radius": "8px",
    },
    "&:focus": {
      border: "1px solid #336EF4 !important",
      "border-radius": "8px",
    },
    };
});

const LessIcon = styled(ExpandLessIcon)(({ theme }) =>{
  return {
    color: theme.palette.surface.white,
    position: 'absolute',
    bottom: '0.5rem',
    right: '0.5rem'
  };
});

const MoreIcon = styled(ExpandMoreIcon)(({ theme }) =>{
  return {
    color: theme.palette.surface.white,
    position: 'absolute',
    bottom: '0.5rem',
    right: '0.5rem'
  };
});

const ButtonFilterFull = ({ list, handleItemClick, selectedItem, searchView , title, addMarginTop}) => {
  const [open, setOpen] = useState(false);
  const [listAux, setList] = useState(list);
  const { t } = useTranslation();
  const showFilters = () => {
    if (!open) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleClick = (event, item) => {
    handleItemClick(event, item);
    setOpen(false);
  };

  const searchChange = (event) => {
    if (event.target.value === t("Search"))
    {
      setList(list);
    }
    else
    {
      setList(list.filter(item => item.name.toLowerCase().indexOf(event.target.value.toLowerCase()) > -1));
    }
  };

  const MoreLessIcon = () => {
    return open ? (
        <LessIcon style={{color: "#FFFFFF"}} />
    ) : (
        <MoreIcon style={{color: "#FFFFFF"}} />
    );
  };

  return (
    <Grid style={{marginTop: addMarginTop ? "-34px" : "0px"}}>
      <FilterBtn
        fullWidth
        endIcon={<MoreLessIcon />}
        onClick={showFilters}>{selectedItem ? 
          (selectedItem.name === undefined ? selectedItem.firstName + " " + selectedItem.lastName : selectedItem.name) :
          title}</FilterBtn>
    { open && 
    <GridList cols={1} >
    {searchView && <MyTextField 
        type="search"
        autoComplete="off"
        placeholder={t("Search")}
        id="txtSearch"
        onChange={searchChange}
        InputProps={{disableUnderline: true,
        }}
      />}
      <StyledList>
        {listAux.map((card, index) => (
          <StyledButton
            key={card.id}
            selected={selectedItem ? card.id === selectedItem.id : false}
            onClick={(event) => handleClick(event, card)}
          >
            <DivSpaceBetween>
              <ListItemText
                primary={card.name === undefined ? card.firstName + " " + card.lastName : card.name}/>
              <ListItemIcon>
                {selectedItem ? (selectedItem.id === card.id ? <CheckCircleIcon style={{marginLeft: "30px", marginTop: "5px"}}/> : <RadioButtonUncheckedIcon style={{marginLeft: "30px", marginTop:"5px"}} />) : <RadioButtonUncheckedIcon style={{marginLeft: "30px", marginTop: "5px"}} />}
              </ListItemIcon>
            </DivSpaceBetween> 
          </StyledButton>
        ))}
      </StyledList>
    </GridList>
    }
    </Grid>
  );
}

export default ButtonFilterFull;