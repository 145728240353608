import React, { useState } from "react";
import ButtonFilert  from "./ui/ButtonFilter"
import ButtonDateRange from "./ui/ButtonDatePickerRange"
import styled from '@emotion/styled'
import {
  Box,
  Button,
  Dialog,
  Grid,
  Card,
  CardContent,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Typography,
} from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from "react-i18next";
import DialogAddStop from "./ui/DialogAddStop";
import SubMenu from "./ui/SubMenu";
import { Chart as ChartJS, ArcElement, Tooltip as TooltipJS, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import theme from "../theme";

ChartJS.register(ArcElement, TooltipJS, Legend);

const BoxMain = styled(Box)(({ theme }) => {
  return {
    height: "100vh",
    paddingTop: "52px",
    paddingRight: "60px",
    paddingLeft: "60px",
  };
});

const DivSpaceBetween = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "start",
    "align-items": "baseline",
    marginTop: "35px"
  };
});

const DivSpaceBetweenVertical = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    flexDirection: "column",
    "justify-content": "start",
    "align-items": "baseline",
  };
});

const ButtonAddStops = styled(Button)(({ theme }) => {
  return {
    textTransform: "none",
    backgroundColor: theme.palette.status.success,
    marginLeft: "110px",
    width: "160px",
    fontSize: "16px",
    fontWeight: 400,
    height: "40px",
    borderRadius: "4px",
    color: theme.palette.text.primary,
    "&:hover": {
      backgroundColor: theme.palette.status.success,
    },
  };
});

const GridCharts = styled(Grid)(({ theme }) => {
  return {
    display: "flex",
    "flex-wrap": "wrap",
    "justify-content": "start",
    "align-items": "flex-start",
    verticalAlign: "top",
    marginTop: "35px", 
  };
});

const MyTableCellHdr = styled(TableCell)(({ theme }) => {
  return {
    color: theme.palette.surface.hardGray,
    align: "center",
    fontSize: "16px",
    fontWeight: 800,
  }
});

const MyTableCell = styled(TableCell)(({ theme }) => {
  return {
    color: theme.palette.surface.hardGray,
    fontSize: "14px",
    fontWeight: 500,
  }
});

const MyTextField = styled(TextField)(({ theme }) => {
  return {
    color: theme.palette.surface.hardGray,
    fontSize: "14px",
    fontWeight: 500,
  }
});

const TitleDoughnut = styled(Typography)(({ theme }) => {
  return {
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: "24px",
    letterSpacing: 0,
    lineHeight: "35px",
    marginLeft: "30px",
  };
});

const options = [
  {
    id: "1",
    path: "/capturer/stops",
    text: "Stops",
  },
  {
    id: "2",
    path: "/capturer/talent",
    text: "Talent",
  },
];

const Stops = ({
  listMachines,
  handleItemClickMachine,
  SelectedItemMachine,
  listShift,
  handleItemClickShift,
  SelectedItemShift,
  listOperators,
  handleItemClickOperator,
  SelectedItemOperator,
  listStops,
  handleItemClickStop,
  SelectedItemStop, 
  startDate,
  endDate,
  handleChangeDate,
  title,
  config,
  stopsData,
  listStopCauses,
}) => {
  const [addDialogAddStopOpen, setDialogAddStopOpen] = useState(
    false
  );
  const { t } = useTranslation();
  const [showIcons, setShowIcons] = useState(stopsData);

  const handleChangeShowIcons = (currentIndex) => {
    const newItems = showIcons.map((stop, index) => {
      stop.isExpanded = currentIndex === index ? !stop.isExpanded : stop.isExpanded;
      return stop;
    });
    setShowIcons(newItems);
  }; 

  return [
    <SubMenu optionsList={options} />,
    <BoxMain>
        <DivSpaceBetween>
          <ButtonDateRange 
            startDate ={startDate}
            endDate ={endDate}
            handleChangeDate = {handleChangeDate}
          />
          <ButtonFilert 
            list={listMachines} 
            handleItemClick = {handleItemClickMachine} 
            selectedItem={SelectedItemMachine} 
            searchView={true} 
            title={t("Machines")}
          />

          <ButtonFilert 
            list={listShift} 
            handleItemClick = {handleItemClickShift} 
            selectedItem={SelectedItemShift} 
            searchView={false} 
            title={t("Shift")}
          />

          <ButtonFilert 
            list={listOperators} 
            handleItemClick = {handleItemClickOperator} 
            selectedItem={SelectedItemOperator} 
            searchView={true} 
            title={t("Operators")}
          />

          <ButtonFilert 
            list={listStops} 
            handleItemClick = {handleItemClickStop} 
            selectedItem={SelectedItemStop} 
            searchView={false} 
            title={t("Stops")}
          />

          <ButtonAddStops
            onClick={() => setDialogAddStopOpen(true)}
          >
            {t("Capture Stop")}
          </ButtonAddStops>

        </DivSpaceBetween>
        <Card sx={{ width: "100%" , height: "600px", marginTop: "-25px"}}>
          <CardContent>
            <GridCharts container xs={12} spacing={1}>
              <Grid xs={5}>
                <TitleDoughnut>
                  {title}
                </TitleDoughnut>
                <Doughnut
                  data={config.data}
                  options={config.options}
                  plugins={config.plugins}
                  style={{height: "300px !important", width: "content-box", marginTop: "15px"}}
                />
              </Grid>
              <Grid xs={7}>
                <TableContainer sx={{
                  height: 500    
                }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <MyTableCellHdr align="center">Orden de trabajo</MyTableCellHdr>
                        <MyTableCellHdr align="center">Fecha</MyTableCellHdr>
                        <MyTableCellHdr align="center">Área</MyTableCellHdr>
                        <MyTableCellHdr align="center">Causa</MyTableCellHdr>
                        <MyTableCellHdr align="center">Responsable</MyTableCellHdr>
                        <MyTableCellHdr align="center"></MyTableCellHdr>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stopsData.map((st, index) => (
                        <TableRow
                          key={st.id}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <MyTableCell component="th" scope="row" align="center">
                            <MyTextField variant="standard"
                              required
                              disabled={!st.isExpanded}
                              InputProps={{ disableUnderline: !st.isExpanded }}
                              type="text"
                              inputMode="numeric"
                              pattern="[0-9]*"
                              defaultValue={st.order}
                            />
                          </MyTableCell>
                          <MyTableCell align="center">
                            <MyTextField variant="standard"
                              disabled={!st.isExpanded} 
                              InputProps={{ disableUnderline: !st.isExpanded }}
                              defaultValue={st.date}
                            />
                          </MyTableCell>
                          <MyTableCell align="center">
                            <MyTextField variant="standard" 
                              disabled={!st.isExpanded} 
                              InputProps={{ disableUnderline: !st.isExpanded }}
                              defaultValue={st.place}
                            />
                          </MyTableCell>
                          <MyTableCell align="center">
                            <MyTextField variant="standard" 
                              disabled={!st.isExpanded} 
                              InputProps={{ disableUnderline: !st.isExpanded }}
                              defaultValue={st.cause}/>
                          </MyTableCell>
                          <MyTableCell align="center">
                            <MyTextField variant="standard" 
                              disabled={!st.isExpanded} 
                              InputProps={{ disableUnderline: !st.isExpanded }}
                              defaultValue={st.responsible}/>
                          </MyTableCell>
                          <MyTableCell align="center">
                            {!st.isExpanded &&
                              <IconButton onClick={()=> {handleChangeShowIcons(index)}}>
                                <EditIcon style={{color: "#9EA1A3"}}/>
                              </IconButton>
                            }
                            {st.isExpanded && (
                              <DivSpaceBetweenVertical>
                                <IconButton onClick={()=> {handleChangeShowIcons(index)}}>
                                  <DoneAllIcon style={{color: theme.palette.status.success}}/>
                                </IconButton>
                                <IconButton onClick={()=> {handleChangeShowIcons(index)}}>
                                  <DoDisturbIcon style={{color: "#9EA1A3"}}/>
                                </IconButton>
                                <IconButton onClick={()=> {handleChangeShowIcons(index)}}>
                                  <DeleteIcon style={{color: theme.palette.status.red}}/>
                                </IconButton>
                              </DivSpaceBetweenVertical>
                            )}
                          </MyTableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table> 
                </TableContainer>
              </Grid>
            </GridCharts>
          </CardContent>
        </Card>

        {
          <Dialog
            open={addDialogAddStopOpen}
            onClose={() => setDialogAddStopOpen(false)}
          >
            <DialogAddStop
              resetModal={() => {
                setDialogAddStopOpen(false);
              }}
              listOper={listOperators}
              listStopCauses={listStopCauses}
              listMachines={listMachines}
              listShifts={listShift}
            />
          </Dialog>
        }
    </BoxMain>
  ];
};



export default Stops;