import React, {useState} from "react";
import { useTranslation } from "react-i18next";
import CalendarIcon from "@mui/icons-material/CalendarToday";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "../../styles.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file


const ButtonDateRangePickerRange = ({ startDate, endDate, handleChangeDate }) => {
  const [open, setOpen] = React.useState(false);
  const { i18n } = useTranslation();
  const [value, onChange] = useState([new Date(startDate), new Date(endDate)]);
  const showCalendar = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    handleChangeDate(value);
    setOpen(false);
  };
  return (
      <div style={{width: "246px"}} onClick={showCalendar}>
      <DateRangePicker
          onChange={onChange}
          onCalendarClose={handleClose}
          value={value}
          calendarIcon={<CalendarIcon style={{ color: "white", height: "18px"}}/>}
          clearIcon={null}
          locale={i18n.language === "es" ? "ES-es" : "En-en"}
          className="inputCalendar"
          isOpen={open}
          disabled={true}
          format={"dd/MM/yyyy"}
        />
      </div>
  );

}


export default ButtonDateRangePickerRange;