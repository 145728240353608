import React from "react";
import styled from '@emotion/styled'
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Card,
  CardContent,
  Checkbox 
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Apartment from '@mui/icons-material/Apartment';
import Delete from '@mui/icons-material/Delete';

const BoxMain = styled(Box)(({ theme }) => {
  return {
      height: "100vh",
      paddingTop: theme.spacing(2),
      paddingRight: "10px",
      paddingLeft: "10px",
  }
});

const TypographyTitle = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    color: "#3A3A3A",
    fontFamily: "Muli",
    fontWeight: "bold",
      textAlign: "center"
  }
});

const DivClassHotizontalBetween = styled('div')(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "space-between",
    "align-items": "end",
    width: "100%"
  }
});

const DivClassHotizontal = styled('div')(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "align-items": "center",
  }
});

const IconButtonSettings = styled(IconButton)(({ theme }) => {
  return {
    height: "32px",
    width:"32px",
    marginTop: "18px",
    "z-index": 3,
    '& svg': {
      fill: "#3A3A3A",
    },
    borderRadius: "0px",
    marginLeft: "10px"
  }
});

const CardMain = styled(Card)(({ theme }) => {
  return {
    marginTop: "10px",
    border: "1px solid #979797",
    boxShadow: "none"
  }
});

const CardMainContent = styled(CardContent)(({ theme }) => {
  return {
    padding: "20px",
    paddingBottom: "12px !important"
  }
});

const CardNotificationTitle = styled(Typography)(({ theme }) => {
  return {
    color: "#3A3A3A",
    "font-family": "Muli",
    "font-size": "14px",
    marginTop:"0px",
    marginBottom: "2px",
    marginLeft: "5px"
  }
});

const CardNotificationTime = styled(Typography)(({ theme }) => {
  return {
    color: "#3A3A3A",
    "font-family": "Muli",
    "font-size": "10px",
    "letter-spacing": 0,
    "line-height": "9px",
    "font-weight": "300",
    marginTop:"0px",
    marginBottom: "2px",
  }
});

const CardNotificationDesc = styled(Typography)(({ theme }) => {
  return {
    color: "#3A3A3A",
    "font-family": "Muli",
    "font-size": "14px",
    "letter-spacing": 0,
    "line-height": "20px",
    "font-weight": "600",
    marginTop:"10px",
  }
});

const ButtonCancel = styled(Button)(({ theme}) => {
  return {
    width: "49%",
    height: "40px",
    borderRadius: "4px",
    marginLeft: "4px",
    color: "#3D4247",
    backgroundColor: "#E1E0E0",
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
    marginTop:"29px",
    "@media screen and  (max-width: 436px)": {
      width: "100%",
      marginTop: "5px",
      marginLeft: "0px",
    },
  };
});

const ButtonAccept = styled(Button)(({ theme}) => {
  return {
    width: "49%",
    height: "40px",
    borderRadius: "4px",
    marginLeft: "4px",
    color: theme.palette.surface.white,
    backgroundColor: "#336EF4",
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
    marginTop:"29px",
    "@media screen and  (max-width: 436px)": {
      width: "100%",
      marginTop: "5px",
      marginLeft: "0px",
    },
  };
});

const StyledCheckBox = styled((props) => (
  <Checkbox {...props} />
))(({ theme, checked }) => ({
  color: "#336EF4 !important",
            '& .Mui-checked': {
              color: "#336EF4 !important",
            },
            '& .MuiSvgIcon-root': {
              color: "#336EF4 !important",
            }
}));

const NotificationsMobile = () => {
  const { t } = useTranslation();
  const [showDelete, setShowDelete] = React.useState(false);
  const NavLink = (props) => (
    <Link
        {...props}
        getProps={({ isPartiallyCurrent }) => {
            return {
                style: {
                    opacity: isPartiallyCurrent ? "1" : "0.5",
                    color: "transparent",
                    marginRight: "18px",
                    "text-decoration": "none"
                },
            };
        }}
    />
  );

  const handleShowDelete = () => {
    setShowDelete(!showDelete);
  };

  return(
    <BoxMain>
      <DivClassHotizontalBetween>
        <div></div>
        <TypographyTitle >{t("Notifications")}</TypographyTitle>
        <IconButtonSettings onClick={handleShowDelete}>
        {
          !showDelete && 
            <Delete />
        }
          </IconButtonSettings>

      </DivClassHotizontalBetween>

      <DivClassHotizontal>
        {
          showDelete && 
          <StyledCheckBox false />
        }
        
        <NavLink to="/notification/details" style={{"text-decoration": "none", "width": showDelete ? "80vw" : "100%"}}>
          <CardMain>
            <CardMainContent>
              <Box>
              <DivClassHotizontalBetween>
                <DivClassHotizontal>
                  <Apartment/>
                  <CardNotificationTitle >Asignación paro</CardNotificationTitle>
                </DivClassHotizontal>
                <div></div>
                <CardNotificationTime>Hace 1 hora</CardNotificationTime>
              </DivClassHotizontalBetween>
              <CardNotificationDesc >José Pérez te ha asignado el paro Falta de material en la máquina M101.</CardNotificationDesc>
              </Box>
            </CardMainContent>
          </CardMain>
        </NavLink>
      </DivClassHotizontal>

      {
        showDelete && 
        <DivClassHotizontalBetween>
          <ButtonCancel onClick={handleShowDelete}>
            {t("Cancel")}
          </ButtonCancel>
          <ButtonAccept onClick={handleShowDelete}>
            {t("Accept")}
          </ButtonAccept>
      </DivClassHotizontalBetween>
      }
      
  </BoxMain>
  );
}

export default NotificationsMobile;