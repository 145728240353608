import React from "react";
import styled from '@emotion/styled'
import {
  Typography,
  Avatar
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';


const DivContainer = styled("div")(({ theme }) => {
  return {
    minHeight: "70px",
    marginBottom: "5px",
    width: "50%",
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "baseline",
  };
});

const TitleMachine = styled(Typography)(({ theme }) => {
  return {
    fontSize: "14px",
    width: "49%",
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: "600",
    marginLeft: "0px",
    marginRight: "10px",
    marginTop: "5px",
    marginBottom: "5px",
  };
});

const AvatarUser = styled(Avatar)(({ theme }) => {
  return {
      width: "60px",
      height: "60px",
      marginLeft: "50px",
  }
});

const BoxMachineEmpty = styled('div')(({ theme }) => {
  return {
    width: "160px",
    height: "180px",
    background: "#eaebee",
    marginTop: "5px",
    marginBottom: "25px",
    "border-radius": "8px",
    "outline": "dashed 1px black"
  };
});

const BoxMachine = styled('div')(({ theme }) => {
  return {
    width: "160px",
    height: "180px",
    background: "#3D4247",
    marginTop: "5px",
    marginBottom: "25px",
    "border-radius": "8px",
    "position": "relative"
  };
});

const TextUser = styled(Typography)(({ theme }) => {
  return {
    fontSize: "0.85rem",
    color: theme.palette.surface.white,
    letterSpacing: "0",
    textAlign: "center",
    alignSelf: "center",
    marginTop: "12px",
  };
});

const MyIcon = styled(CloseIcon)(({ theme }) => {
  return {
    display: "flex",
    fontSize: "1rem",
    fontWeight: "bold",
    padding: "12px",
    marginLeft: "120px",
    color: theme.palette.surface.white,
    alignSelf: "center",
    marginRight: "12px",
  };
});

const MachineAllocation = ({ 
  machine,
  operator,
  handlerOpenDialog,
  handleClick,
}) => {
  
  return (
    <DivContainer>
      <TitleMachine>
        {machine.name}
      </TitleMachine>
      {!operator && (
        <BoxMachineEmpty onClick={() => handlerOpenDialog(null, machine)}/>
      )}
      {operator && (
        <BoxMachine onClick={() => handlerOpenDialog(operator, machine)}>
          <MyIcon onClick={() => handleClick(machine.id)}/>
          <AvatarUser src={operator.pictureUrl}>
            {operator.name === undefined ? operator.firstName.charAt(0) : operator.name.charAt(0)}
          </AvatarUser>
          <TextUser>
            {operator.name === undefined ? operator.firstName + " " + operator.lastName : operator.name.split(" ").slice(0,2).join(" ")}
          </TextUser>
        </BoxMachine>
      )}
    </DivContainer>   
  );
}

export default MachineAllocation;