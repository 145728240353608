import React, { useState } from "react";
import styled from '@emotion/styled'
import {
  Box,
  Button,
  IconButton,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { Chart as ChartJS, ArcElement, Tooltip as TooltipJS, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ButtonFilterFull from "./ui/ButtonFilterFull";
import ButtonDateRangeFull from "./ui/ButtonDatePickerRangeFull";

ChartJS.register(ArcElement, TooltipJS, Legend);

const BoxContent = styled(Box)(({ theme }) => {
  return {
    height: "100vh",
    padding: "0px",
  };
});

const BoxMain = styled(Box)(({ theme }) => {
  return {
    height: "100vh - 57px",
    paddingTop: theme.spacing(2),
    paddingRight: "8px",
    paddingLeft: "8px",
  };
});

const BoxMainMobile = styled(Box)(({ theme }) => {
  return {
    height: "100vh + 57px",
    paddingTop: theme.spacing(2),
    paddingRight: "8px",
    paddingLeft: "8px",
    paddingBottom: "0px",
  };
});


const Title = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    color: "#3A3A3A",
    fontFamily: "Muli",
    fontWeight: "bold",
  };
});

const DivCenter = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "center",
    "align-items": "center",
    marginTop: "10px"
  };
});

const DivCenterMobile = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "center",
    "align-items": "center",
    marginTop: "-5px",
  };
});

const ButtonFilter = styled(Button)(({ theme}) => {
  return {
    backgroundColor: theme.palette.surface.btn_blue,
    height: "40px",
    borderRadius: "4px",
    color: theme.palette.surface.white,
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
    marginTop: "20px",
    marginBottom: "8px",
  };
});

const ButtonFilterMobile = styled(Button)(({ theme}) => {
  return {
    backgroundColor: theme.palette.surface.btn_blue,
    height: "40px",
    borderRadius: "4px",
    color: theme.palette.surface.white,
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
    marginTop: "42px",
  };
});

const MyBackButton = styled(IconButton)(({ theme }) => {
  return {
    paddingRight: "40px",
    paddingBottom: "10px",
    color: "#3D4247",
    position: 'absolute',
    left: '2rem',
    fontSize: "12px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.surface.white,
    },
  };
});

const TitleDoughnut = styled(Typography)(({ theme }) => {
  return {
    color: "#3A3A3A",
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: "15px",
    letterSpacing: 0,
    lineHeight: "14px",
  };
});

const SubtitleDoughnut = styled(Typography)(({ theme }) => {
  return {
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: "12px",
    letterSpacing: 0,
    lineHeight: "14px",
    marginTop: "3px",
  };
});

const DashboardMobile = ({
  listMachines,
  handleItemClickMachine,
  SelectedItemMachine,
  listShift,
  handleItemClickShift,
  SelectedItemShift,
  listOperators,
  handleItemClickOperator,
  SelectedItemOperator,
  listStops,
  handleItemClickStop,
  SelectedItemStop, 
  startDate,
  endDate,
  handleChangeDate,
  configJS,
  title,
  subtitle,
  optionsBar,
  seriesBar,
}) => {

  const { t } = useTranslation();
  const [ShowFilters, setShowFilters] = useState(false);
  const handleFilters = () => {
    if (!ShowFilters) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
  };
  
  return (
    <BoxContent>
      <BoxMain style={{paddingBottom: !ShowFilters ? "60px" : "0px"}}>
        {!ShowFilters && (
          <>
            <DivCenter>
              <Title>{t("Dashboard")}</Title>
            </DivCenter>
            <ButtonFilter
              onClick={handleFilters} 
              fullWidth
            >
              {t("Filters")}
            </ButtonFilter>
            <Card sx={{ width: "100%" , height: "750px", marginTop: "0px", marginBottom: "20px"}}>
              <CardContent>
                  <TitleDoughnut>
                    {title}
                  </TitleDoughnut>
                  <SubtitleDoughnut>
                    {subtitle}
                  </SubtitleDoughnut>
                  <Doughnut
                    data={configJS.data}
                    options={configJS.options}
                    plugins={configJS.plugins}
                    style={{height: "400px !important", width: "content-box"}}
                  />
                  <ReactApexChart
                    options={optionsBar}
                    series={seriesBar}
                    type="bar"
                    width="100%"
                    height={400}
                    style={{marginTop: "40px"}}
                  />
              </CardContent>
            </Card>
          </> 
        )}
        {ShowFilters && (
          <BoxMainMobile>
            <DivCenterMobile>
              <MyBackButton onClick={handleFilters}>
                <ArrowBackIosIcon />
              </MyBackButton>
              <Title>{t("Filters")}</Title>
            </DivCenterMobile>
            <ButtonDateRangeFull
              startDate ={startDate}
              endDate ={endDate}
              handleChangeDate = {handleChangeDate}
            />
            <ButtonFilterFull
              addMarginTop={true}
              list={listMachines} 
              handleItemClick = {handleItemClickMachine} 
              selectedItem={SelectedItemMachine} 
              searchView={true} 
              title={t("Machines")}
            />
            <ButtonFilterFull
              list={listShift} 
              handleItemClick = {handleItemClickShift} 
              selectedItem={SelectedItemShift} 
              searchView={false} 
              title={t("Shift")}
            />
            <ButtonFilterFull
              list={listOperators} 
              handleItemClick = {handleItemClickOperator} 
              selectedItem={SelectedItemOperator} 
              searchView={true} 
              title={t("Operators")}
            />
            <ButtonFilterFull
              list={listStops} 
              handleItemClick = {handleItemClickStop} 
              selectedItem={SelectedItemStop} 
              searchView={false} 
              title={t("Stops")}
            />
            <ButtonFilterMobile
              onClick={handleFilters} 
              fullWidth
            >
            {t("Filter")}
            </ButtonFilterMobile>
          </BoxMainMobile>
        )}
      </BoxMain>
    </BoxContent>
  );
};

export default DashboardMobile;