import React, { useContext } from "react";
import styled from '@emotion/styled'
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Avatar,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { UserContext } from "../context/auth";
import logo from "../images/logo-l.png";
import DarkModeIcon from '@mui/icons-material/DarkMode';
import ExitToApp from '@mui/icons-material/ExitToApp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import BuildIcon from '@mui/icons-material/Build';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

const BoxMain = styled(Box)(({ theme }) => {
    return {
        height: "100vh",
        paddingTop: theme.spacing(2),
        paddingRight: "10px",
        paddingLeft: "10px",
    }
});

const TypographyTitle = styled(Typography)(({ theme }) => {
    return {
        fontSize: "16px",
        color: "#3A3A3A",
        fontFamily: "Muli",
        fontWeight: "bold",
        textAlign: "center"
    }
});

const DivName = styled('div')(({ theme }) => {
    return {
        "display": "flex",
        "flex-wrap": "wrap",
        "justify-content": "start",
        "align-items": "center",
        marginTop: "30px"
    }
});

const AvatarIcon = styled(Avatar)(({ theme }) => {
    return {
        height: "36px",
        width: "36px"
    }
});

const NameText = styled(Typography)(({ theme }) => {
    return {
        color: "#3A3A3A",
        "font-family": "Muli",
        "font-size": "14px",
        "font-weight": 600,
        "letter-spacing": 0,
        "line-height": "14px",
        marginLeft: "10px"
    }
});

const SubnameText = styled(Typography)(({ theme }) => {
    return {
        color: "#8D8D8D",
        "font-family": "Muli",
        "font-size": "12px",
        "letter-spacing": 0,
        "line-height": "9px",
        marginLeft: "10px",
        marginTop: "9px"
    }
});

const BtnMain = styled(Button)(({ theme }) => {
    return {
        "box-sizing": "border-box",
        border: "0.5px solid #979797",
        "border-radius": "4px",
        "background-color": "#FFFFFF",
        color: "#3A3A3A",
        "font-family": "Muli",
        "font-size": "16px",
        "font-weight": 600,
        "letter-spacing": 0,
        "line-height": "16px",
        height: "60px",
        marginTop: "28px",
        width:"100%",
        "justify-content": "flex-start",
        "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        "& .MuiButton-label":{
            "justify-content": "flex-start",
            textTransform: "inherit"
        },
    }
});

const BtnMainSub = styled(Button)(({ theme }) => {
    return {
        "box-sizing": "border-box",
        border: "0.5px solid #979797",
        "border-radius": "4px",
        "background-color": "#FFFFFF",
        color: "#3A3A3A",
        "font-family": "Muli",
        "font-size": "16px",
        "font-weight": 600,
        "letter-spacing": 0,
        "line-height": "16px",
        height: "60px",
        marginTop: "8px",
        width:"100%",
        "justify-content": "flex-start",
        "&:hover": {
            backgroundColor: "#FFFFFF",
          },
        "& .MuiButton-label":{
            "justify-content": "flex-start",
            textTransform: "inherit"
        },
    }
});

const BtnSupport = styled(Button)(({ theme }) => {
    return {
        "box-sizing": "border-box",
        border: "0.5px solid #979797",
        "border-radius": "4px",
        "background-color": "transparent",
        color: "#3A3A3A",
        "font-family": "Muli",
        "font-size": "16px",
        "font-weight": 600,
        "letter-spacing": 0,
        "line-height": "16px",
        height: "60px",
        width:"100%",
        "justify-content": "flex-start",
        "& .MuiButton-label":{
        "justify-content": "flex-start"
        },
    }
});

const Bottom = styled('div')(({ theme }) => {
    return {
        position: "fixed",
        width: "-webkit-fill-available",
        bottom: "70px",
    }
});

const Logo = styled('img')(({ theme }) => {
    return {
        height: "40px",
        color:"black"
    }
});

const BtnText = styled(Typography)(({ theme }) => {
    return {
        color: "#3A3A3A",
        "font-family": "Muli",
        "font-size": "16px",
        "font-weight": 600,
        "letter-spacing": 0,
        "line-height": "14px",
        width: "75%",
        textAlign: 'initial',
        "text-transform": "inherit"
    }
});

const ProfileMobile = () => {
    const { t } = useTranslation();
    const { userData } = useContext(
      UserContext
    );
    const [showSupport, setShowSupport] = React.useState(false);
    const { firstName, lastName, username, profile: { picture } = {} } = userData || {};
    const pictureUrl = picture ;
    const NavLink = (props) => (
        <Link
            {...props}
            getProps={({ isPartiallyCurrent }) => {
                return {
                    style: {
                        opacity: isPartiallyCurrent ? "1" : "0.5",
                        color: "transparent",
                        marginRight: "18px",
                        "text-decoration": "none"
                    },
                };
            }}
        />
    );
    const handleSupport = () => {
        setShowSupport(!showSupport);
    };
    const downIcon = showSupport ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/> 

    return(
      <BoxMain>
      <TypographyTitle >{t("Profile")}</TypographyTitle>
      <DivName>
        <AvatarIcon src={pictureUrl}>{`${
                    firstName && firstName.charAt(0)
            }`}</AvatarIcon>
        <Box>
          <NameText>{firstName + " "+ lastName}</NameText>
          <SubnameText >{username}</SubnameText>
        </Box>
      </DivName>
      <BtnMain startIcon={<DarkModeIcon style={{marginRight: "10px", marginLeft: "33px"}} />}>{t("DarkMode")}</BtnMain>
      <Box>      
        <BtnMainSub onClick={handleSupport} endIcon={downIcon} startIcon={<BuildIcon style={{marginRight: "10px", marginLeft: "33px"}} />}><BtnText>{t("ContactToSupport")}</BtnText></BtnMainSub>
        {
            showSupport && <>
                <BtnSupport href='http://api.whatsapp.com/send?phone=528125401908' target="_blank" startIcon={<WhatsAppIcon style={{marginRight: "10px", marginLeft: "33px"}} />}>{t("WhatsApp")}</BtnSupport>
                <BtnSupport href='mailto:support@example.com?subject=SendMail&body=Description' target="_blank" startIcon={<EmailIcon style={{marginRight: "10px", marginLeft: "33px"}} />}>{t("Correo")}</BtnSupport>
            </>
        }
        </Box>
      <NavLink to="/logout" style={{"text-decoration": "none"}}><BtnMainSub startIcon={<ExitToApp style={{marginRight: "10px", marginLeft: "33px"}} />}>{t("Logout")}</BtnMainSub></NavLink>
      <Bottom>
        <Logo src={logo} alt="Inicio" />
      </Bottom>
    </BoxMain>
    );
}

export default ProfileMobile;