import React from "react";
import styled from '@emotion/styled'
import {
  Box,
  Typography,
  IconButton,
  Card,
  CardContent
} from "@mui/material";
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

const BoxMain = styled(Box)(({ theme }) => {
  return {
      height: "100vh",
      paddingTop: theme.spacing(2),
      paddingRight: "10px",
      paddingLeft: "10px",
  }
});

const TypographyTitle = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    color: "#3A3A3A",
    fontFamily: "Muli",
    fontWeight: "bold",
      textAlign: "center"
  }
});

const DivClassHotizontalBetween = styled('div')(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "space-between",
    "align-items": "end",
    width: "100%"
  }
});

const IconButtonSettings = styled(IconButton)(({ theme }) => {
  return {
    height: "32px",
    width:"32px",
    marginTop: "18px",
    "z-index": 3,
    '& svg': {
      fill: "#3A3A3A",
    },
    borderRadius: "0px",
    marginLeft: "10px"
  }
});

const CardMain = styled(Card)(({ theme }) => {
  return {
    marginTop: "10px",
    border: "1px solid #979797",
    boxShadow: "none"
  }
});

const CardMainContent = styled(CardContent)(({ theme }) => {
  return {
    padding: "20px",
    paddingBottom: "12px !important"
  }
});

const CardNotificationTitle = styled(Typography)(({ theme }) => {
  return {
    color: "#3D4247",
    "font-family": "Muli",
    "font-size": "14px",
    "letter-spacing": 0,
    "line-height": "14px",
    "font-weight": "0",
    opacity: "0.5",
    marginTop:"0px",
    marginBottom: "2px",
  }
});

const CardNotificationDesc = styled(Typography)(({ theme }) => {
  return {
    color: "#3A3A3A",
    "font-family": "Muli",
    "font-size": "14px",
    "letter-spacing": 0,
    "line-height": "16px",
    "font-weight": "0",
    marginBottom:"5px",
  }
});

const TypographyMachineName = styled(Typography)(({ theme }) => {
  return {
    color: "#3A3A3A",
    "font-family": "Muli",
    "font-size": "14px",
    "letter-spacing": 0,
    "line-height": "16px",
    "font-weight": "600",
    marginTop:"31px",
    marginBottom: "2px",
    marginLeft: "5px"
  }
});

const TypographyDate = styled(Typography)(({ theme }) => {
  return {
    color: "#3A3A3A",
    "font-family": "Muli",
    "font-size": "14px",
    "letter-spacing": 0,
    "line-height": "16px",
    "font-weight": "0",
    marginTop:"0px",
    marginBottom: "2px",
    marginLeft: "5px"
  }
});

const NotificationDetail = () => {

  const handleGoBack = () => {
    window.history.back();
  };

  return(
    <BoxMain>
      <DivClassHotizontalBetween>
        <IconButtonSettings onClick={handleGoBack}>
          <ArrowBackIos />
        </IconButtonSettings>
        <TypographyTitle >Asignación paro</TypographyTitle>
        <div></div>
      </DivClassHotizontalBetween>

      <DivClassHotizontalBetween>
        <TypographyMachineName >Máquina M101</TypographyMachineName>
        <TypographyDate >07 Mar 22 - 11:00 am</TypographyDate>
      </DivClassHotizontalBetween>
      
      <CardMain>
        <CardMainContent>
          <Box>
          <DivClassHotizontalBetween>
            <Box>
              <CardNotificationDesc >123</CardNotificationDesc>
              <CardNotificationTitle >Orden</CardNotificationTitle>
            </Box>

            <Box>
              <CardNotificationDesc >Mantenimiento</CardNotificationDesc>
              <CardNotificationTitle >Area</CardNotificationTitle>
            </Box>
          </DivClassHotizontalBetween>

          <DivClassHotizontalBetween style={{marginTop: "16px"}}>
            <Box>
              <CardNotificationDesc >Paro 1</CardNotificationDesc>
              <CardNotificationTitle >Tipo de paro</CardNotificationTitle>
            </Box>

            <Box>
              <CardNotificationDesc >Fallo mecánico</CardNotificationDesc>
              <CardNotificationTitle >Causa</CardNotificationTitle>
            </Box>
          </DivClassHotizontalBetween>

          </Box>
        </CardMainContent>
      </CardMain>
  </BoxMain>
  );
}

export default NotificationDetail;