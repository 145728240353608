import React, {useState} from "react";
import {
  Button,
  Box,
  Typography,
  FormHelperText,
  InputBase,
  IconButton,
} from "@mui/material";
import styled from '@emotion/styled'
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import logo from "../images/logo-l.png";
import UserIcon from '@mui/icons-material/AccountCircleOutlined';
import ShowPass from '@mui/icons-material/RemoveRedEyeOutlined';
import HidePass from '@mui/icons-material/VisibilityOffOutlined';
import theme from "../theme";

const ContainerMain = styled('div') (({ theme }) => {
  return {
    "display": "flex",
    flexDirection: "column",
    height: "100%",

  };
});

const BoxMain = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    verticalAlign: "center",
    fullWidth: true,
    margin: "0px",
    "@media screen and (min-width: 600px)": {
      marginTop: "64px !important",
    }
  };
});

const BoxLogin = styled(Box)(({ theme }) => {
  return {
    justifyContent: "center",
    marginTop: "0px",
    marginBottom: "90px",
    marginLeft: "auto",
    marginRight: "auto",
    verticalAlign: "center",
    flexDirection: "column",
  };
});

const TitleLogin = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "bold",
    color: "#0E1630",
    flexGrow: 1,
    textAlign: "center",
    marginTop: "47px",
  }
});

const ButtonSub = styled(Button)(({ theme }) => {
  return {
    backgroundColor: theme.palette.surface.btn_blue,
    color: "#FFFFFF",
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
    textTransform: "none",
    width: "340px !important",
    height: "40px",
    marginTop: "100px",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.surface.btn_blue,
    },
  }
});

const TextForgot = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "600",
    color: "#3D4247 !important",
    lineHeight: "24px",
    flexGrow: 1,
    textAlign: "center",
    marginTop: "95px",
    textDecoration: "none"
  }
});

const StyledInput = styled((props) => 
{
  return <InputBase {...props} />
}
)(({ theme, value,}) => ({
  color: theme.palette.surface.btn_blue,
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    width: "340px !important",
    height: "40px",
    paddingLeft: "8px",
    marginTop: "34px",
    border: !value ? '1px solid #EFEFEF' : '1px solid #336EF4',
    borderBottom: "1px solid #336EF4",
    backgroundColor: "#EFEFEF",
    borderRadius: 4,
    input: {
      color: "#000 !important",
      "font-family": "Muli",
      "font-size": "14px",
      "letter-spacing": 0,
      "line-height": "14px",
    }
}));

const Login = ({ submitForm, error }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      username: yup.string().required(),
      password: yup.string().required(),
    }),
    onSubmit: ({ username, password }) => {
      submitForm({
        variables: {
          username,
          password,
        },
      });
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  let IconPassword = showPassword ? HidePass : ShowPass;
  const handleShowPass = () => {
    setShowPassword(!showPassword);
  };

  return (
    <ContainerMain column maxWidth={false}>
      <BoxMain>
        <BoxLogin>
          <img src={logo} style={{width: "240px", height: "70px", marginRight: "auto", marginLeft: "auto", marginTop: "118px", display: "block"}} alt="Flexbaze logo" />
          <TitleLogin>
            {t("Sign in")}
          </TitleLogin>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <StyledInput 
                margin="normal" 
                required  
                placeholder={t("Username")}
                id="username" 
                name="username"
                autoComplete="username"
                endAdornment={<UserIcon style={{ color: theme.palette.surface.btn_blue, paddingRight: "8px" }}/>}
                autoFocus
                onChange={formik.handleChange}
                value={formik.values.username}
                error={formik.errors.username && formik.touched.username}/>
            </div>
            <div>
              <StyledInput 
                margin="normal" 
                required 
                placeholder={t("Password")}
                type={showPassword ? "text" : "password"}
                endAdornment={<IconButton onClick={handleShowPass}><IconPassword style={{ color: theme.palette.surface.btn_blue, paddingRight: "8px" }}/></IconButton>}
                id="password"
                autoComplete="current-password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.errors.password && formik.touched.password}/>  
            </div>
            {error && (
              <FormHelperText style={{marginLeft: "80px"}} error>Credenciales incorrectas</FormHelperText>
            )}
            <ButtonSub
              type="submit"
              variant="contained"
            >
              {t("Sign in")}
            </ButtonSub>
          </form>
          <TextForgot>
            {t("ForgotPassword")}
          </TextForgot>
        </BoxLogin>
      </BoxMain>
    </ContainerMain>)
};

export default Login;
