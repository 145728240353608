import React, { useState } from "react";
import DashboardDesk from "../Components/Dashboard"
import moment from "moment-timezone";
import { CircularProgress, useMediaQuery } from "@mui/material";
import DashboardMobile from "../Components/DashboardMobile";
import { GET_MACHINES, GET_USERS, GET_SHIFTS } from "./Dashboard.queries";
import { useQuery } from "@apollo/client";
import styled from '@emotion/styled';

let endDate = moment.utc();
let startDate = moment.utc().subtract(7, "days");
startDate.hour(0);
startDate.minute(0);
startDate.second(0);
startDate.millisecond(0);

const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const Dashboard = () => {

  const [SelectEdndDate, setEndDate] = useState(endDate);
  const [SelectedStartDate, setStartDate] = useState(startDate);
  const [SelectedItemMachine, setSelectedItemMachine] = useState();
  const [listMachines, setListMachines] = useState([]);
  const [listOperators, setListOperators] = useState([]);
  const [listShifts, setListShifts] = useState([]);

  const { loading: loadingMachines } = useQuery(GET_MACHINES, {
    onCompleted: (data) => {
      setListMachines(data.getOwnCompanies[0].machines.sort(function(a,b){
        return a.name.localeCompare(b.name);
      }));
    }
  });

  const { loading: loadingOperators } = useQuery(GET_USERS, {
    onCompleted: (data) => {
      setListOperators(data.searchUsers.sort(function(a,b){
        return (a.firstName + " " + a.lastName).localeCompare((b.firstName + " " + b.lastName));
      }));
    },
    variables: {"input": ""}
  });

  const { loading: loadingShifts } = useQuery(GET_SHIFTS, {
    onCompleted: (data) => {
      setListShifts(data.getShiftTypesByCompany.sort(function(a,b){
        return a.name.localeCompare(b.name);
      }));
    },
    variables: {"companyId": "1"}
  });

  const handleItemClickMachine = (event, item) => {
    if (SelectedItemMachine)
    {
      if (SelectedItemMachine.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemMachine();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemMachine(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemMachine(item);
    }
  };

  const [SelectedItemShift, setSelectedItemShift] = useState();

  const handleItemClickShift = (event, item) => {
    if (SelectedItemShift)
    {
      if (SelectedItemShift.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemShift();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemShift(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemShift(item);
    }
  };


  const [SelectedItemOperator, setSelectedItemOperator] = useState();

  const handleItemClickOperator = (event, item) => {
    if (SelectedItemOperator)
    {
      if (SelectedItemOperator.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemOperator();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemOperator(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemOperator(item);
    }
  };


  const [SelectedItemStop, setSelectedItemStop] = useState();

  const listStops = [
  ]

  const handleItemClickStop = (event, item) => {
    if (SelectedItemStop)
    {
      if (SelectedItemStop.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemStop();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemStop(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemStop(item);
    }
  };

  const handleChangeDate = (date) => {
    startDate = moment(date[0]);
    startDate.hour(0);
    startDate.minute(0);
    startDate.second(0);
    startDate.millisecond(0);

    endDate = moment(date[1]);
    endDate.hour(23);
    endDate.minute(59);
    endDate.second(59);
    setEndDate(endDate);
    setStartDate(startDate);
  };

  const data = {
    series: [
      {
        name: "Encendida sin producir",
        data: [14, 5, 11, 27, 12, 3, 11, 4, 18, 20],
      },
      {
        name: "Produciendo",
        data: [24, 15, 1, 17, 22, 23, 20, 7, 23, 12],
      },
      {
        name: "Apagada",
        data: [4, 15, 11, 7, 2, 13, 16, 19, 11, 6],
      }
    ],
    options: {
      colors: ["#00A54E", "#336EF4", "#D2434A"],
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        offsetY: -14,
        style: {
          fontFamily: "Muli",
          fontSize: "14px",
          fontWeight: "bold",
          colors: ["#3D4247", "#3D4247", "#3D4247"],
        },
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
        axisBorder: {
          show: false,
        },
        toolbar: {
          show: false,
        }
      },
      states: {
        hover: { filter: { type: "none" } },
        active: { filter: { type: "none" } },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "25%",
          distributed: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['transparent']
      },
      title: {
        text: 'Paros',
        style: {
          fontSize: "21px",
          color: "#3D4247",
          fontFamily: "Muli",
          fontWeight: 600,
          marginBottom: "100px"
        },
      },
      xaxis: {
        categories: ["01/03", "02/03", "03/03", "04/03", "05/03", "06/03", "07/03", "08/03", "09/03", "10/03"],
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          style: {
            fontSize: "14px",
            color: "#3D4247",
            fontFamily: "Muli",
            fontWeight: 600,
          },
        },
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: function (val) {
            return val;
          }
        }
      },
      fill: {
        opacity: 1,
        colors: ["#00A54E", "#336EF4", "#D2434A"],
      },
      legend: {
        position: 'top',
        fontFamily: "Muli",
        fontSize: "18px",
        horizontalAlign: 'right',
        customLegendItems: ["Encendida sin producir", "Produciendo", "Apagada"],
        markers: {
          width: 14,
          height: 14,
          radius: 14,
          fillColors: {
            colors: ["#00A54E", "#336EF4", "#D2434A"],
          },
        },
      },
    },
  };

  const dataMobile = {
    series: [
      {
        name: "Encendida sin producir",
        data: [14, 5, 11, 27, 12, 3, 11, 4, 18, 20],
      },
      {
        name: "Produciendo",
        data: [24, 15, 1, 17, 22, 23, 20, 7, 23, 12],
      },
      {
        name: "Apagada",
        data: [4, 15, 11, 7, 2, 13, 16, 19, 11, 6],
      }
    ],
    options: {
      colors: ["#00A54E", "#336EF4", "#D2434A"],
      grid: {
        show: false,
      },
      dataLabels: {
        enabled: true,
        offsetY: -14,
        style: {
          fontFamily: "Muli",
          fontSize: "12px",
          fontWeight: "bold",
          colors: ["#3D4247", "#3D4247", "#3D4247"],
        },
      },
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        stackType: '100%',
        axisBorder: {
          show: false,
        },
        toolbar: {
          show: false,
        }
      },
      states: {
        hover: { filter: { type: "none" } },
        active: { filter: { type: "none" } },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "25%",
          distributed: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['transparent']
      },
      title: {
        text: 'Paros',
        style: {
          fontSize: "15px",
          color: "#3D4247",
          fontFamily: "Muli",
          fontWeight: 600,
        },
      },
      xaxis: {
        categories: ["01/03", "02/03", "03/03", "04/03", "05/03", "06/03", "07/03", "08/03", "09/03", "10/03"],
        show: false,
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          style: {
            fontSize: "12px",
            color: "#3D4247",
            fontFamily: "Muli",
            fontWeight: 600,
          },
        },
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: function (val) {
            return val;
          }
        }
      },
      fill: {
        opacity: 1,
        colors: ["#00A54E", "#336EF4", "#D2434A"],
      },
      legend: {
        position: 'top',
        fontFamily: "Muli",
        fontSize: "12px",
        horizontalAlign: 'center',
        customLegendItems: ["Encendida sin producir", "Produciendo", "Apagada"],
        markers: {
          width: 9,
          height: 9,
          radius: 9,
          fillColors: {
            colors: ["#00A54E", "#336EF4", "#D2434A"],
          },
        },
      },
    },
  };

  const chartDataMobileJS = {
    labels: ["Encendida sin producir", "Produciendo", "Apagada"],
    datasets: [
      {
        label: "Estado",
        data: [162, 162, 216],
        backgroundColor: ["#00A54E", "#336EF4", "#D2434A"],
        borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
        borderWidth: 1,
        cutout: "85%",
        offset: 2,
      }
    ],
  };

  const chartDataJS = {
    labels: ["Encendida sin producir", "Produciendo", "Apagada"],
    datasets: [
      {
        label: "Estado",
        data: [162, 162, 216],
        backgroundColor: ["#00A54E", "#336EF4", "#D2434A"],
        borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF"],
        borderWidth: 1,
        cutout: "85%",
        offset: 2,
      }
    ],
  };

  const matchesDoughnutLabelsLine = useMediaQuery('(min-width:900px)');

  const doughnutLabelsLine = {
    id: "doughnutLabelsLine",
    afterDraw(chart, args, options) {
      const {ctx, chartArea: { width, height }} = chart;
  
      let total = 0;
  
      chart.data.datasets[0].data.forEach((value, idx) => {
        total += value;
      });
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
  
          const halfWidth = width / 2;
          const halfHeight = height / 2;
  
          const xLine = x >= halfWidth ? x + 20 : x - 20;
          const yLine = y >= halfHeight ? y + 20 : y - 20;
          const extraLine = x >= halfWidth ? (width/5) : - (width/5);
  
          ctx.beginPath();
          ctx.moveTo(x,y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = "#3D4247";
          ctx.stroke();
  
          ctx.font = matchesDoughnutLabelsLine ? "12px Muli":"1vw Muli";
          const textXPosition = x >= halfWidth ? "right" : "left";
          ctx.textAlign = textXPosition;
          ctx.textBaseline = "bottom";
          ctx.fillStyle = "#3D4247";
          const extraYLine = matchesDoughnutLabelsLine ? 15: 10;
          ctx.fillText(chart.data.labels[index], xLine + extraLine, yLine + extraYLine,100);
          ctx.font = matchesDoughnutLabelsLine ? "12px Muli":"1vw Muli";
          ctx.textAlign = textXPosition;
          ctx.fillText((chart.data.datasets[i].data[index]/total) * 100 + "% (" + chart.data.datasets[i].data[index] + " Hrs.)", xLine + extraLine, yLine - 5);
          ctx.font = "20px Muli";
          ctx.textAlign = "left";
          ctx.fillText(total, halfWidth, halfHeight);
          ctx.fillText("Hrs. Totales", halfWidth - 30, halfHeight + 30);
        });
      });
    }
  };

  const doughnutLabelsLineMobile = {
    id: "doughnutLabelsLine",
    afterDraw(chart, args, options) {
      const {ctx, chartArea: { width, height }} = chart;
  
      let total = 0;
  
      chart.data.datasets[0].data.forEach((value, idx) => {
        total += value;
      });
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
  
          const halfWidth = width / 2;
          const halfHeight = height / 2;
  
          const xLine = x >= halfWidth ? x + 20 : x - 20;
          const yLine = y >= halfHeight ? y + 20 : y - 20;
          const extraLine = x >= halfWidth ? 70 : - 70;
  
          ctx.beginPath();
          ctx.moveTo(x,y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = "#3D4247";
          ctx.stroke();
  
          ctx.font = "2vw Muli";
          const textXPosition = x >= halfWidth ? "right" : "left";
          ctx.textAlign = textXPosition;
          ctx.textBaseline = "bottom";
          ctx.fillStyle = "#3D4247";
          ctx.fillText(chart.data.labels[index], xLine + extraLine, yLine + 15, 70);
          ctx.font = "2vw Muli";
          ctx.textAlign = textXPosition;
          ctx.fillText((chart.data.datasets[i].data[index]/total) * 100 + "% (" + chart.data.datasets[i].data[index] + " Hrs.)", xLine + extraLine, yLine - 5);
          ctx.font = "3.3vw Muli";
          ctx.textAlign = "center";
          ctx.fillText(total, halfWidth + 25, halfHeight +20);
          ctx.fillText("Hrs. Totales", halfWidth+ 25 , halfHeight+40);
        });
      });
    }
  };

  const optionsJS = {
    layout: {
      padding: 20,
    },
    responsive: true,
    aspectRatio: 1.8,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        position: "top",
        text: "PREN-001",
        align: "start",
        fontFamily: "Muli",
        color: "#3A3A3A",
        font: {
          size: 15,
          lineHeight: "14px",
          weight: 600,
        }
      },
      subtitle: {
        display: true,
        text: startDate.format("DD MMM YY") + " - " + endDate.format("DD MMM YY"),
        align: "start",
        fontFamily: "Muli",
        color: "#3D4247",
        font: {
          size: 12,
          lineHeight: "14px",
        }
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const optionsMobileJS = {
    layout: {
      padding: 28,
    },
    responsive: true,
    aspectRatio: 1.8,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const config = {
    type: "doughnut",
    data: chartDataJS,
    options: optionsJS,
    plugins: [doughnutLabelsLine],
  };

  const configJS = {
    type: "doughnut",
    data: chartDataMobileJS,
    options: optionsMobileJS,
    plugins: [doughnutLabelsLineMobile],
  };

  const matches = useMediaQuery('(min-width:600px)');

  if (loadingMachines || loadingOperators || loadingShifts)
    return <LoadingProgress />;

  return (
    <>
      {
        matches && <DashboardDesk 
          listMachines={listMachines} 
          handleItemClickMachine = {handleItemClickMachine} 
          SelectedItemMachine={SelectedItemMachine} 
        
          listShift={listShifts} 
          handleItemClickShift = {handleItemClickShift} 
          SelectedItemShift={SelectedItemShift} 
        
          listOperators={listOperators} 
          handleItemClickOperator = {handleItemClickOperator} 
          SelectedItemOperator={SelectedItemOperator} 
        
          listStops={listStops} 
          handleItemClickStop = {handleItemClickStop} 
          SelectedItemStop={SelectedItemStop} 
        
          startDate={SelectedStartDate.format("DD MMM YY")}
          endDate={SelectEdndDate.format("DD MMM YY")}
          handleChangeDate={handleChangeDate}
        
          config={config}      
          optionsBar={data.options}
          seriesBar={data.series} 
        />
      }
      {
        !matches && <DashboardMobile
          listMachines={listMachines} 
          handleItemClickMachine = {handleItemClickMachine} 
          SelectedItemMachine={SelectedItemMachine} 
        
          listShift={listShifts} 
          handleItemClickShift = {handleItemClickShift} 
          SelectedItemShift={SelectedItemShift} 
        
          listOperators={listOperators} 
          handleItemClickOperator = {handleItemClickOperator} 
          SelectedItemOperator={SelectedItemOperator} 
        
          listStops={listStops} 
          handleItemClickStop = {handleItemClickStop} 
          SelectedItemStop={SelectedItemStop} 
        
          startDate={SelectedStartDate.format("DD MMM YY")}
          endDate={SelectEdndDate.format("DD MMM YY")}
          handleChangeDate={handleChangeDate}
        
          configJS={configJS}
          title={optionsJS.plugins.title.text}
          subtitle={optionsJS.plugins.subtitle.text}
          optionsBar={dataMobile.options}
          seriesBar={dataMobile.series}
        />
      }
    </>
  )
}

export default Dashboard;