import React, { useState } from "react";
import styled from '@emotion/styled'
import {
  Box,
  Button,
  IconButton,
  InputBase,
  Card,
  CardContent,
  createTheme,
  FormControl,
  FormHelperText,
  Grid,
  Select,
  Typography,
  ThemeProvider,
} from "@mui/material";
import { Formik } from "formik";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ButtonFilterFull from "./ui/ButtonFilterFull";
import ButtonDateRangeFull from "./ui/ButtonDatePickerRangeFull";
import DeleteIcon from '@mui/icons-material/Delete';
import { Chart as ChartJS, ArcElement, Tooltip as TooltipJS, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import * as Yup from "yup";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TalentMobile from "./TalentMobile";

ChartJS.register(ArcElement, TooltipJS, Legend);

const BoxContent = styled(Box)(({ theme }) => {
  return {
    height: "100vh",
    padding: "0px",
  };
});

const BoxMain = styled(Box)(({ theme }) => {
  return {
    height: "100vh - 57px",
    paddingTop: theme.spacing(2),
    paddingRight: "8px",
    paddingLeft: "8px",
  };
});

const BoxMainMobile = styled(Box)(({ theme }) => {
  return {
    height: "100vh + 57px",
    paddingTop: theme.spacing(2),
    paddingRight: "8px",
    paddingLeft: "8px",
    paddingBottom: "0px",
  };
});

const Title = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    color: "#3A3A3A",
    fontFamily: "Muli",
    fontWeight: "bold",
  };
});

const DivCenter = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "center",
    "align-items": "center",
    marginTop: "10px"
  };
});

const DivCenterMobile = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "center",
    "align-items": "center",
    marginTop: "-5px",
  };
});

const ButtonFilterMobile = styled(Button)(({ theme}) => {
  return {
    backgroundColor: theme.palette.surface.btn_blue,
    height: "40px",
    borderRadius: "4px",
    color: theme.palette.surface.white,
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
    marginTop: "24px",
  };
});

const BtnFilterSmall = styled(Button)(({ theme}) => {
  return {
    backgroundColor: theme.palette.surface.btn_blue,
    height: "40px",
    width: "49%",
    borderRadius: "4px",
    color: theme.palette.surface.white,
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
    marginRight: "4px",
    "@media screen and  (max-width: 415px)": {
      width: "100%",
      marginRight: "0px",
    },
  };
});

const MyBackButton = styled(IconButton)(({ theme }) => {
  return {
    paddingRight: "40px",
    paddingBottom: "10px",
    color: "#3D4247",
    position: 'absolute',
    left: '2rem',
    fontSize: "12px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.surface.white,
    },
  };
});

const DivSpaceBetween = styled("div")(({ theme }) => {
  return {
    backgroundColor: "#9D9D9D",
    marginTop: "20px",
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "space-around",
    "align-items": "baseline",
    borderRadius: "4px",
  };
});

const DivBetween = styled("div")(({ theme }) => {
  return {
    marginTop: "8px",
    marginBottom: "8px",
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "space-around",
    "align-items": "baseline",
  };
});

const DivBetweenSpace = styled("div")(({ theme }) => {
  return {
    marginTop: "8px",
    marginBottom: "8px",
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "space-between",
    "align-items": "baseline",
  };
});

const DivWithLine = styled("div")(({ theme }) => {
  return {
    borderTop: "1px solid #3D4247",
  };
});

const BtnStops = styled(Button)(({ theme}) => {
  return {
    width: "50%",
    height: "40px",
    borderRadius: "4px",
    color: theme.palette.surface.white,
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
  };
});

const BtnTalent = styled(Button)(({ theme}) => {
  return {
    width: "50%",
    height: "40px",
    borderRadius: "4px",
    color: theme.palette.surface.white,
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
  };
});

const GreenBtn = styled(Button)(({ theme}) => {
  return {
    width: "49%",
    height: "40px",
    borderRadius: "4px",
    marginLeft: "4px",
    color: theme.palette.surface.white,
    backgroundColor: theme.palette.status.success,
    fontSize: "14px",
    textTransform: "none",
    fontFamily: "Muli",
    fontWeight: 600,
    "@media screen and  (max-width: 415px)": {
      width: "100%",
      marginTop: "5px",
      marginLeft: "0px",
    },
  };
});

const DateTimePickerMain = styled(DateTimePicker)(({ theme }) => {
  return {
    "& .MuiTextField-root":{
      border: '0.5px solid #979797',
    },
    "& .MuiOutlinedInput-notchedOutline":{
      border: '0.5px solid #979797',
    },
    width: "100% !important",
    marginRight: "5px"
  };
});

const themePicker = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        day: {
          color: "#3D4247",
          backgroundColor: "#101630",
          borderRadius: "0px",
        },
        container: {
          backgroundColor: "#101630",
        },
        daySelected: {
          backgroundColor: "#336ef4",
          borderRadius: "50%",
          color: "#3D4247",
        },
        dayDisabled: {
          color: "#3D4247",
        },
        root: {
          color: "#3D4247",
          backgroundColor: "#FFFFFF",
          ".MuiPickersDay-root.Mui-selected": {
            backgroundColor: "#3D4247",
          },
        },
      }
    },
    MuiPopper:{
      styleOverrides: {
        root: {
          marginTop: "-54px !important",
        },
      }
    },
    MuiTextField:{
      styleOverrides: {
        root: {
          width: "100% !important",
          marginRight: "5px !important",
        },
      }
    },
    MuiSvgIcon:{
      styleOverrides: {
        root: {
          color: "#3D4247 !important",
        },
      }
    },
    MuiTypography:{
      styleOverrides: {
        root: {
          color: "#3D4247 !important",
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
        },
      }
    },
  },
});

const GridSpace = styled(Grid)(({ theme }) => {
  return {
    marginBottom: "25px",
  };
});

const SelectMain = styled(Select)(({ theme }) => {
  return {
    color: "black",
    borderRadius: "0px !important",
    height: "31px",
    marginTop: "6px",
    border: "0px",
  };
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: "0px",
  },
  '& .MuiInputBase':{
    borderRadius: "0px !important"
  },
  '& .MuiInputBase-input': {
    color: "#3D4247",
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '0.5px solid #979797',
    borderRadius: "4px !important",
    fontSize: 12,
    opacity: "50%",
    padding: '10px 12px 10px 12px',
  },
}));

const TitleDate = styled(Typography)(({ theme }) => {
  return {
    fontFamily: "Muli",
    fontSize: "12px",
    color: "#3A3A3A",
  };
});

const TitleDoughnut = styled(Typography)(({ theme }) => {
  return {
    color: "#3A3A3A",
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: "15px",
    letterSpacing: 0,
    lineHeight: "14px",
  };
});

const SubtitleDoughnut = styled(Typography)(({ theme }) => {
  return {
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: "12px",
    letterSpacing: 0,
    lineHeight: "14px",
    marginTop: "3px",
  };
});

const CardTitle = styled(Typography)(({ theme }) => {
  return {
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: "16px",
    letterSpacing: 0,
    lineHeight: "14px",
  };
});

const CardDate = styled(Typography)(({ theme }) => {
  return {
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: 600,
    fontSize: "10px",
    letterSpacing: 0.25,
    lineHeight: "9px",
  };
});

const CardDesc = styled(Typography)(({ theme }) => {
  return {
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: 0,
    fontSize: "14px",
    letterSpacing: 0,
    lineHeight: "16px",
  };
});

const DivClassHotizontal = styled('div')(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "align-items": "center",
  }
});

const DeleteIconClass = styled(DeleteIcon)(({ theme }) => {
  return {
    "position": "absolute", 
    "top": "16px", 
    "left": "37%", 
    color: "white"
  }
});

const DeleteButton = styled(Button)(({ theme }) => {
  return {
    borderRadius: "0px",
    backgroundColor: "#D2434A", 
    color:"white", 
    height:"91px", 
    width:"23%", 
    marginLeft:"5px",
    '&:hover': {
      backgroundColor: "#D2434A", 
    },
  }
});

const CapturerMobile = ({
  listMachines,
  handleItemClickMachine,
  SelectedItemMachine,
  listShift,
  handleItemClickShift,
  SelectedItemShift,
  listOperators,
  handleItemClickOperator,
  SelectedItemOperator,
  listStops,
  handleItemClickStop,
  SelectedItemStop, 
  startDate,
  endDate,
  handleChangeDate,
  configJS,
  title,
  subtitle,
  barOptions,
  barSeries,
  isStopSelected,
  stopsData,
  listStopCauses,
}) => {
  const { t } = useTranslation();
  const [ShowFilters, setShowFilters] = useState(false);
  const [ShowList, setShowList] = useState(false);
  const [ShowAddStop, setShowAddStop] = useState(false);
  const [ShowAddTalent, setShowAddTalent] = useState(false);
  const [isOptSelected, setIsOptSelected] = useState(isStopSelected);
  const handleStop = () => {
    if (!isOptSelected) {
      setIsOptSelected(true);
    } else {
      setIsOptSelected(false);
      setShowList(false);
    }
  };

  const [showIcons, setShowIcons] = useState(stopsData);

  const handleChangeShowIcons = (currentIndex) => {
    const newItems = showIcons.map((stop, index) => {
      stop.isExpanded = currentIndex === index ? !stop.isExpanded : stop.isExpanded;
      return stop;
    });
    setShowIcons(newItems);
  }; 

  const handleFilters = () => {
    if (!ShowFilters) {
      setShowFilters(true);
    } else {
      setShowFilters(false);
    }
  };

  const handleClickCard = () => {
    if(isOptSelected){
      setShowList(!ShowList)
    }
  }

  const addStop = () => {
    if (!ShowAddStop) {
      setShowAddStop(true);
    } else {
      setShowAddStop(false);
    }
  };

  const addTalent = () => {
    if (!ShowAddTalent) {
      setShowAddTalent(true);
    } else {
      setShowAddTalent(false);
    }
  };
  
  return (
    <BoxContent>
      <BoxMain style={{paddingBottom: !ShowFilters ? "60px" : "0px"}}>
        {!ShowFilters && !ShowAddTalent && !ShowAddStop && (
          <>
            <DivCenter>
              <Title>{t("Capturer")}</Title>
            </DivCenter>
            <DivSpaceBetween>
              <BtnStops
                style={{backgroundColor : isOptSelected ? "#336EF4" : "#9D9D9D"}}
                onClick={handleStop}
              >
                {t("Stops")}
              </BtnStops>
              <BtnTalent
                style={{backgroundColor : !isOptSelected ? "#336EF4" : "#9D9D9D"}}
                onClick={handleStop}
              >
                {t("Talent")}
              </BtnTalent>
            </DivSpaceBetween>
            <DivBetween>
              <BtnFilterSmall
                onClick={handleFilters} 
              >
                {t("Filters")}
              </BtnFilterSmall>
              {isOptSelected && (
                <GreenBtn
                  onClick={addStop}
                >
                  {t("Capture Stop")}
                </GreenBtn>
              )}
              {!isOptSelected && (
                <GreenBtn
                  onClick={addTalent}
                >
                  {t("Talent allocation")}
                </GreenBtn>
              )}
            </DivBetween>
            <Card  sx={{ width: "100%" , height: "auto", marginTop: "0px", marginBottom: "20px"}}>
              <CardContent>
                {isOptSelected && (
                  <>
                    <TitleDoughnut>
                      {title}
                    </TitleDoughnut>
                    <SubtitleDoughnut>
                      {subtitle}
                    </SubtitleDoughnut>
                    <Doughnut
                      onClick={handleClickCard}
                      data={configJS.data}
                      options={configJS.options}
                      plugins={configJS.plugins}
                      style={{height: "300px !important", width: "content-box", marginTop: "15px"}}
                    />
                  </>
                )}
                {!isOptSelected && (
                  <ReactApexChart
                    options={barOptions}
                    series={barSeries}
                    type="bar"
                    height={320}
                  />
                )}
                {ShowList &&
                  stopsData.map((stop, index) => (
                    <>
                    <DivWithLine>
                    <DivClassHotizontal>
                      <div style={{width: stop.isExpanded ? "75%" : "100%"}} onClick={()=> {handleChangeShowIcons(index)}}>
                        <DivBetweenSpace>
                          <CardTitle>
                            #{stop.order}
                          </CardTitle>
                          <CardDate>
                            {stop.date}
                          </CardDate>
                        </DivBetweenSpace>

                        <DivBetweenSpace style={{marginTop: "13px"}}>
                          <CardDesc >
                            {stop.place}
                          </CardDesc>
                          <CardDesc>
                            {t("Stops")} {stop.id}
                          </CardDesc>
                        </DivBetweenSpace>

                        <DivBetweenSpace style={{marginBottom: "16px"}}>
                          <CardDesc>
                            {stop.cause}
                          </CardDesc>
                          <CardDesc>
                            {stop.responsible}
                          </CardDesc>
                        </DivBetweenSpace>
                      </div>
                      {
                        stop.isExpanded && <>
                          <DeleteButton endIcon={<DeleteIconClass />} style={{}}>{t("Delete")}</DeleteButton>
                        </>
                      }
                    </DivClassHotizontal>
                    </DivWithLine>
                    </>
                  ))
                }
              </CardContent>
            </Card>
          </>         
        )}
        {ShowFilters && (
          <BoxMainMobile>
            <DivCenterMobile>
              <MyBackButton onClick={handleFilters}>
                <ArrowBackIosIcon />
              </MyBackButton>
              <Title>{t("Filters")}</Title>
            </DivCenterMobile>
            <ButtonDateRangeFull
              startDate ={startDate}
              endDate ={endDate}
              handleChangeDate = {handleChangeDate}
            />
            <ButtonFilterFull
              addMarginTop={true}
              list={listMachines} 
              handleItemClick = {handleItemClickMachine} 
              selectedItem={SelectedItemMachine} 
              searchView={true} 
              title={t("Machines")}
            />
            <ButtonFilterFull
              list={listShift} 
              handleItemClick = {handleItemClickShift} 
              selectedItem={SelectedItemShift} 
              searchView={false} 
              title={t("Shift")}
            />
            <ButtonFilterFull
              list={listOperators} 
              handleItemClick = {handleItemClickOperator} 
              selectedItem={SelectedItemOperator} 
              searchView={true} 
              title={t("Operators")}
            />
            <ButtonFilterFull
              list={listStops} 
              handleItemClick = {handleItemClickStop} 
              selectedItem={SelectedItemStop} 
              searchView={false} 
              title={t("Stops")}
            />
            <ButtonFilterMobile
              onClick={handleFilters} 
              fullWidth
            >
            {t("Filter")}
            </ButtonFilterMobile>
          </BoxMainMobile>
        )}
        {ShowAddTalent && (
          <TalentMobile 
            listMachines={listMachines}
            listShift={listShift}
            listOperators={listOperators}
            handleItemClickShift={handleItemClickShift}
            SelectedItemShift={SelectedItemShift}
            addTalent={addTalent} 
          />
        )}
        {ShowAddStop && (
          <>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid item container justify="center">
           <Formik
               validationSchema={Yup.object().shape({
                 causeId: Yup.number().required(t("Required")),
                 machineId: Yup.number().required(t("Required")),
                 shiftId: Yup.number().required(t("Required")),
                 operatorId: Yup.number().required(t("Required")),
                 description: Yup.string().required(t("Required")),
                 startDate: Yup.date().required(t("Required")),
                 endDate: Yup.date().required(t("Required")),
               })}
               initialValues={{
                 causeId: 0,
                 machineId: 0,
                 shiftId: 0,
                 operatorId: 0,
                 description: "",
                 startDate: new Date(),
                 endDate: new Date(),
               }}
               onSubmit={({ causeId, machineId, shiftId, operatorId, description, startDate, endDate,}) => {
                 addStop();
               }}
             >
                {({
                 values,
                 touched,
                 errors,
                 handleSubmit,
                 handleBlur,
                 setFieldValue,
                 handleChange,
                 dirty,
               }) => (
                 <BoxMainMobile onSubmit={handleSubmit}>
                    <DivCenterMobile>
                      <MyBackButton onClick={addStop}>
                        <ArrowBackIosIcon />
                      </MyBackButton>
                      <Title>{t("Capture Stop")}</Title>
                    </DivCenterMobile>
                   <Grid container style={{marginTop: "35px"}}>
                   
                     <GridSpace item xs={12}>
                     <TitleDate>{t("Start date and time")}</TitleDate>
                     <ThemeProvider theme={themePicker}>
                       <DateTimePickerMain
                         renderInput={(props) => <BootstrapInput {...props} fullWidth/>}
                         label=""
                         value={values.startDate}
                         onChange={(newValue) => {
                         }}
                       />
                     </ThemeProvider>
                     </GridSpace>
     
                     <GridSpace item xs={12}>
                     <TitleDate>{t("End date and time")}</TitleDate>
                     <ThemeProvider theme={themePicker}>
                     <DateTimePickerMain
                       renderInput={(props) => <BootstrapInput {...props} fullWidth/>}
                       label=""
                       value={values.endDate}
                       onChange={(newValue) => {
                       }}
                     />
                     </ThemeProvider>
                     </GridSpace>
     
                     <GridSpace item xs={12}>
                      <TitleDate>{t("Cause")}*</TitleDate>
                      <FormControl fullWidth>
                        <SelectMain
                          native
                          value={values.causeId}
                          onChange={(evt) =>{
                            setFieldValue("causeId", evt.target.value);
                          }}
                          onBlur={handleBlur}
                          inputProps={{
                            name: "causeId",
                            id: "outlined-age-native-simple",
                          }}
                          classes={{
                            icon: {color: "black"}
                          }}
                          input={<BootstrapInput />}
                          error={touched.causeId && !!errors.causeId}
                        >
                          <option aria-label="None" value="" ></option>
                          {listStopCauses.map((stopCause) => (
                          <option 
                            aria-label={stopCause.name}
                            value={stopCause.id}>
                            {stopCause.name}
                          </option>
                        ))}
                        </SelectMain>
                      </FormControl>
                        {touched.causeId &&values.causeId === "" && (
                        <FormHelperText error>{t("Required field")}</FormHelperText>
                      )}
                     </GridSpace>
     
                     <GridSpace item xs={12}>
                      <TitleDate>{t("Description")}*</TitleDate>
                      <BootstrapInput fullWidth
                        InputProps={{
                          className: {
                            width: "100%",
                            color: "black",
                            backgroundColor: "white",
                          },
                        }}
                        onBlur={handleBlur}
                        placeholder=""
                        label=""
                        multiline
                        rowsMax={6}
                        rows={1}
                        onChange={handleChange}
                        name="description"
                        value={values.description}
                        error={touched.description && !!errors.description}
                        InputLabelProps={{
                          className: {
                            color: "black",
                            marginBottom: "20px",
                          },
                          classes: {
                            shrink: {
                              color: "black",
                              marginBottom: "20px",
                            },
                          },
                        }}
                      />
                      {touched.description && errors.description && (
                        <FormHelperText error>{t("Required field")}</FormHelperText>
                      )}
                     </GridSpace>
     
                     <GridSpace item xs={12}>
                      <TitleDate>{t("Machine")}</TitleDate>
                         <FormControl fullWidth>
                           <SelectMain
                             native
                             value={values.machineId}
                             onChange={(evt) =>{
                               setFieldValue("machineId", evt.target.value);
                             }}
                             onBlur={handleBlur}
                             inputProps={{
                               name: "machineId",
                               id: "outlined-age-native-simple",
                             }}
                             classes={{
                               icon: {color: "black"}
                             }}
                             input={<BootstrapInput />}
                             error={touched.machineId && !!errors.machineId}
                           >
                             <option aria-label="None" value="" ></option>
                             {listMachines.map((mac) => (
                                <option aria-label={mac.name} value={mac.id}>{mac.name}</option>
                             ))}
                           </SelectMain>
                         </FormControl>
                         {touched.machineId &&values.machineId === "" && (
                         <FormHelperText error>{t("Required field")}</FormHelperText>
                       )}
                     </GridSpace>
     
                     <GridSpace item xs={12}>
                      <TitleDate>{t("Shift")}</TitleDate>
                         <FormControl fullWidth>
                           <SelectMain
                             native
                             value={values.shiftId}
                             onChange={(evt) =>{
                               setFieldValue("shiftId", evt.target.value);
                             }}
                             onBlur={handleBlur}
                             inputProps={{
                               name: "shiftId",
                               id: "outlined-age-native-simple",
                             }}
                             classes={{
                               icon: {color: "black"}
                             }}
                             input={<BootstrapInput />}
                             error={touched.shiftId && !!errors.shiftId}
                           >
                             <option aria-label="None" value="" ></option>
                             {listShift.map((shift) => (
                                <option aria-label={shift.name} value={shift.id}>{shift.name}</option>
                             ))}
                           </SelectMain>
                         </FormControl>
                         {touched.shiftId &&values.shiftId === "" && (
                         <FormHelperText error>{t("Required field")}</FormHelperText>
                       )}
                     </GridSpace>
     
                     <GridSpace item xs={12}>
                      <TitleDate>{t("Operator Asignated")}</TitleDate>
                         <FormControl fullWidth>
                           <SelectMain
                             native
                             value={values.operatorId}
                             onChange={(evt) =>{
                               setFieldValue("operatorId", evt.target.value);
                             }}
                             onBlur={handleBlur}
                             inputProps={{
                               name: "operatorId",
                               id: "outlined-age-native-simple",
                             }}
                             classes={{
                               icon: {color: "black"}
                             }}
                             input={<BootstrapInput />}
                             error={touched.operatorId && !!errors.operatorId}
                           >
                             <option aria-label="None" value="" ></option>
                             {listOperators.map((oper) => (
                                <option 
                                  aria-label={oper.name === undefined ?
                                  oper.firstName + " " + oper.lastName :
                                  oper.name
                                  } 
                                  value={oper.id}>
                                  {oper.name === undefined ? oper.firstName + " " + oper.lastName : oper.name}
                                </option>
                             ))}
                           </SelectMain>
                         </FormControl>
                         {touched.operatorId &&values.operatorId === "" && (
                         <FormHelperText error>{t("Required field")}</FormHelperText>
                       )}
                     </GridSpace>
     
                   </Grid>
                   <Grid container direction="column">
                    <ButtonFilterMobile
                      onClick={addStop} 
                      onBlur={handleBlur}
                      fullWidth
                    >
                    {t("Capture")}
                    </ButtonFilterMobile>
                 </Grid>
                 </BoxMainMobile>
               )}
             </Formik>
         </Grid>
          </LocalizationProvider>
         
         </>
        )}
      </BoxMain>
    </BoxContent>
  );
};

export default CapturerMobile;