import React from "react";
import ProfileMobile from "../Components/ProfileMobile";

const Profile = () => {
    return (
    <>
      <ProfileMobile/>
    </>
    );
  };
  
  export default Profile;