import React from "react";
import styled from '@emotion/styled'
import {
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const DivContainer = styled("div")(({ theme }) => {
  return {
    minHeight: "70px",
    marginBottom: "5px",
    width: "50%",
    flexDirection: "row",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "baseline",
  };
});

const TitleMachine = styled(Typography)(({ theme }) => {
  return {
    fontSize: "14px",
    width: "49%",
    color: "#3D4247",
    fontFamily: "Muli",
    fontWeight: "600",
    marginLeft: "0px",
    marginRight: "10px",
    marginTop: "5px",
    marginBottom: "5px",
  };
});

const BoxMachineEmpty = styled(Box)(({ theme }) => {
  return {
    height: "50px",
    border: "1px dashed #3D4247",
    borderRadius: "4px",
    width: "100%",
    marginRight: "9px",
    backgroundColor: "#E6E6E6",
    paddingBottom: "10px",
    marginBottom: "0px",
  };
});

const BoxMachine = styled(Box)(({ theme }) => {
  return {
    height: "50px",
    border: "1px solid #3D4247",
    borderRadius: "4px",
    width: "100%",
    marginRight: "9px",
    backgroundColor: theme.palette.surface.btn_blue,
    marginBottom: "0px",
    flexDirection: "row",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "baseline",
  };
});

const AvatarUser = styled(Avatar)(({ theme }) => {
  return {
    alignSelf: "center",
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginLeft: "10px",
    letterSpacing: "0",
  };
});

const TextUser = styled(Typography)(({ theme }) => {
  return {
    fontSize: "0.85rem",
    color: theme.palette.surface.white,
    letterSpacing: "0",
    paddingLeft: "5 rem",
    alignSelf: "center",
    marginLeft: theme.spacing(0.5),
    marginRight: "12px",
  };
});

const MyIcon = styled(CloseIcon)(({ theme }) => {
  return {
    fontSize: "1rem",
    fontWeight: "bold",
    color: theme.palette.surface.white,
    alignSelf: "center",
    marginRight: "12px",
  };
});


const MachineContainer = ({ 
  machine,
  operator,
  handlerOpenDialog,
  handleClick,
}) => {

  return (
    <DivContainer>
      <TitleMachine>
        {machine.name}
      </TitleMachine>
      {!operator && (
        <BoxMachineEmpty onClick={() => handlerOpenDialog(null, machine)}/>
      )}
      {operator && (
        <BoxMachine onClick={() => handlerOpenDialog(operator, machine)}>
          <AvatarUser src={operator.pictureUrl}>
            {operator.name === undefined ? operator.firstName.charAt(0) : operator.name.charAt(0)}
          </AvatarUser>
          <TextUser>
            {operator.name === undefined ? operator.firstName + " " + operator.lastName : operator.name.split(" ").slice(0,2).join(" ")}
          </TextUser>
          <MyIcon onClick={() => handleClick(machine.id)}/>
        </BoxMachine>
      )}
    </DivContainer>   
  );
};

export default MachineContainer;