import React from "react";
import {
  Button,
  Box,
  Typography,
  FormHelperText,
  InputBase,
} from "@mui/material";
import styled from '@emotion/styled'
import { useFormik } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import logo from "../images/logo-l.png";
import UserIcon from '@mui/icons-material/AccountCircle';
import WorkIcon from '@mui/icons-material/Work';
import theme from "../theme";

const ContainerMain = styled('div') (({ theme }) => {
  return {
    "display": "flex",
    flexDirection: "column",
    height: "100%",
    backgroundColor: theme.palette.surface.megaLightGray,
    "justify-content": "center",
    "align-items": "center",
    marginTop: "0px",
    "@media screen and (min-width: 600px)": {
      marginTop: "-64px !important",
    }
  };
});

const BoxMain = styled(Box)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "space-between",
    verticalAlign: "center",
    fullWidth: true,
    margin: "0px",
    "@media screen and (min-width: 600px)": {
      marginTop: "64px !important",
    }
  };
});

const BoxLogin = styled(Box)(({ theme }) => {
  return {
    backgroundColor: "#FFFFFF",
    border: "solid 0.5px #979797",
    borderRadius: "8px",
    justifyContent: "center",
    width: "500px",
    height: "620px",
    marginTop: "0px",
    marginBottom: "90px",
    marginLeft: "auto",
    marginRight: "auto",
    verticalAlign: "center",
    flexDirection: "column",
  };
});

const DivBottom = styled('div')(({ theme}) => {
  return {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    marginLeft: "auto",
    marginRight: "auto",
    flexGrow: 1,
    minHeight: "60px",
    width: "100%",
    backgroundColor: "#EEEEEE",
  }
});

const BoxLine = styled(Box)(({ theme }) => {
  return {
    height: "2px !important",
    width: "340px !important",
    backgroundColor: "#C2CAD1",
    marginTop: "20px",
    marginLeft: "auto",
    marginRight: "auto"
  }
});

const TitleLogin = styled(Typography)(({ theme }) => {
  return {
    fontSize: "21px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "600",
    color: "#336EF4",
    flexGrow: 1,
    textAlign: "center",
    marginTop: "30px",
  }
});

const TextCopyright = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "600",
    color: "#8D8D8D",
    lineHeight: "24px",
    textAlign: "center",
    flexGrow: 1,
  }
});

const InputUser = styled(InputBase)(({ theme }) => {
  return {
    color: theme.palette.surface.btn_blue,
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    width: "340px !important",
    height: "45px",
    paddingLeft: "8px",
    marginLeft: "80px",
    marginTop: "50px",
    border: "solid 2px #C2CAD1",
    borderRadius: "8px"
  }
});

const InputPwd = styled(InputBase)(({ theme }) => {
  return {
    color: theme.palette.surface.btn_blue,
    fontFamily: theme.typography.fontFamily,
    fontSize: "16px",
    width: "340px !important",
    height: "45px",
    paddingLeft: "8px",
    marginLeft: "80px",
    marginTop: "16px",
    border: "solid 2px #C2CAD1",
    borderRadius: "8px"
  }
});

const ButtonSub = styled(Button)(({ theme }) => {
  return {
    backgroundColor: theme.palette.surface.btn_blue,
    color: "#FFFFFF",
    fontSize: "21px",
    fontFamily: theme.typography.fontFamily,
    textTransform: "none",
    width: "340px !important",
    height: "45px",
    marginLeft: "80px",
    marginTop: "100px",
    "&:hover": {
      backgroundColor: theme.palette.surface.btn_blue,
    },
  }
});

const TextForgot = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    fontFamily: theme.typography.fontFamily,
    fontWeight: "600",
    color: theme.palette.status.blue,
    textDecoration: "underline",
    lineHeight: "24px",
    flexGrow: 1,
    textAlign: "center",
    marginTop: "30px",
  }
});

const Login = ({ submitForm, error }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: yup.object().shape({
      username: yup.string().required(),
      password: yup.string().required(),
    }),
    onSubmit: ({ username, password }) => {
      submitForm({
        variables: {
          username,
          password,
        },
      });
    },
  });

  return (
    <ContainerMain column maxWidth={false}>
      <BoxMain>
        <BoxLogin>
          <img src={logo} style={{width: "240px", height: "70px", marginRight: "auto", marginLeft: "auto", marginTop: "60px", display: "block"}} alt="Flexbaze logo" />
          <BoxLine/>
          <TitleLogin>
            {t("Sign in")}
          </TitleLogin>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <InputUser 
                margin="normal" 
                required  
                placeholder={t("Username")}
                id="username" 
                name="username"
                autoComplete="username"
                startAdornment={<UserIcon style={{ color: theme.palette.surface.btn_blue, paddingRight: "8px" }}/>}
                autoFocus
                onChange={formik.handleChange}
                value={formik.values.username}
                error={formik.errors.username && formik.touched.username}/>
            </div>
            <div>
              <InputPwd 
                margin="normal" 
                required 
                placeholder={t("Password")}
                type="password"
                startAdornment={<WorkIcon style={{ color: theme.palette.surface.btn_blue, paddingRight: "8px" }}/>}
                id="password"
                autoComplete="current-password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={formik.errors.password && formik.touched.password}/>  
            </div>
            {error && (
              <FormHelperText style={{marginLeft: "80px"}} error>Credenciales incorrectas</FormHelperText>
            )}
            <ButtonSub
              type="submit"
              variant="contained"
            >
              {t("Sign in")}
            </ButtonSub>
          </form>
          <TextForgot>
            {t("ForgotPassword")}
          </TextForgot>
        </BoxLogin>
      </BoxMain>
      <DivBottom>
        <TextCopyright>Copyright &#169; Flexbaze 2022</TextCopyright>
      </DivBottom>
    </ContainerMain>)
};

export default Login;
