import React, { useState } from "react";
import styled from '@emotion/styled';
import {
  Box,
  Dialog,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import DialogTalentMobile from "./ui/DialogTalentMobile";
import { useTranslation } from "react-i18next";
import DropZone from "./ui/DropZone";
import MachineContainer from "./ui/MachineContainer";

const BoxMainMobile = styled(Box)(({ theme }) => {
  return {
    height: "100vh + 57px",
    paddingTop: theme.spacing(2),
    paddingRight: "8px",
    paddingLeft: "8px",
    paddingBottom: "0px",
  };
});

const DivCenterMobile = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "-5px",
  };
});

const MyBackButton = styled(IconButton)(({ theme }) => {
  return {
    paddingRight: "40px",
    paddingBottom: "10px",
    color: "#3D4247",
    position: 'absolute',
    left: '2rem',
    fontSize: "12px",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.surface.white,
    },
  };
});

const Title = styled(Typography)(({ theme }) => {
  return {
    fontSize: "16px",
    color: "#3A3A3A",
    fontFamily: "Muli",
    fontWeight: "bold",
  };
});

const DivMachines = styled("div")(({ theme }) => {
  return {
    marginTop: "8px",
    marginBottom: "8px",
    marginRight: "-10px",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "baseline",
  };
});

const TalentMobile = ({
  listMachines,
  listOperators,
  addTalent,
  
}) => {
  const { t } = useTranslation();
  const [addDialog, setDialog] = useState(false);
  const [machineAssigned, setMachineAssigned] = useState(null);
  const [talentAssigned, setTalentAssigned] = useState(null);
  let talentsAssigned = [];
  for (let i = 0; i < listMachines.length; i++)
  {
    talentsAssigned[i] = null;
  }
  const [talents, setTalents] = useState(talentsAssigned);

  const handlerOpenDialog = (item, machine) => {
    setMachineAssigned(machine);
    setTalentAssigned(item);
    setDialog(true);
  };

  const handleClick = (machineId) => {
    const machineSelected = listMachines.filter((machine) => String(machine.id) === String(machineId));
    const idxTalents= listMachines.indexOf(machineSelected[0]);
    if (idxTalents > -1)
    {
      const talentsAux = talents;
      talentsAux[idxTalents] = null;
      setTalents(talentsAux);
    }
  };

  const assignOperator = (machineId, operatorId) => {
    const machineSelected = listMachines.filter((machine) => String(machine.id) === String(machineId));
    const operatorSelected = listOperators.filter((oper) => String(oper.id) === String(operatorId));
    const idxTalents= listMachines.indexOf(machineSelected[0]);
    if (idxTalents > -1)
    {
      const talentsAux = talents;
      talentsAux[idxTalents] = operatorSelected[0];
      setTalents(talentsAux);
    }
    if (machineId === t("Absences"))
    {
      const existsOper = listAbsences.filter((oper) => oper.id === operatorSelected[0].id);
      if(existsOper.length === 0)
      {
        setListAbsences((listAbsences) => [...listAbsences, operatorSelected[0]]);
      }
    }
    if (machineId === t("Disabled"))
    {
      const existsOper = listDisabled.filter((oper) => oper.id === operatorSelected[0].id);
      if(existsOper.length === 0)
      {
        setListDisabled((listDisabled) => [...listDisabled, operatorSelected[0]]);
      }
    }
    if (machineId === t("VacationsPermissions"))
    {
      const existsOper = listVacations.filter((oper) => oper.id === operatorSelected[0].id);
      if(existsOper.length === 0)
      {
        setListVacations((listVacations) => [...listVacations, operatorSelected[0]]);
      }
    }
  };

  const [listAbsences, setListAbsences] = useState([]);
  const [listDisabled, setListDisabled] = useState([]);
  const [listVacations, setListVacations] = useState([]);

  const handleCloseAbs = (id) => {
    const listAbsencesAux = listAbsences.filter((abs) => id !== abs.id)
    setListAbsences(listAbsencesAux);
  };

  const handleCloseDis = (id) => {
    const listDisabledAux = listDisabled.filter((dis) => id !== dis.id)
    setListDisabled(listDisabledAux);
  };

  const handleCloseVac = (id) => {
    const listVacationsAux = listVacations.filter((vac) => id !== vac.id)
    setListVacations(listVacationsAux);
  };

  return (
    <BoxMainMobile>
      <DivCenterMobile>
        <MyBackButton onClick={addTalent}>
          <ArrowBackIosIcon />
        </MyBackButton>
        <Title>{t("Talent allocation")}</Title>
      </DivCenterMobile>
      <DivMachines>
        {listMachines.map((machine, idx) => (
          <MachineContainer
            key={machine.id}
            machine={machine}
            operator={talents[idx]}
            handlerOpenDialog={handlerOpenDialog}
            handleClick={handleClick}
          />
        ))}
      </DivMachines>
      <DropZone
        title={t("Absences")}
        listOper={listAbsences}
        handlerClose={handleCloseAbs}
        handlerOpenDialog={handlerOpenDialog}
      />
      <DropZone
        title={t("Disabled")}
        listOper={listDisabled}
        handlerClose={handleCloseDis}
        handlerOpenDialog={handlerOpenDialog}
      />
      <DropZone
        title={t("VacationsPermissions")}
        listOper={listVacations}
        handlerClose={handleCloseVac}
        handlerOpenDialog={handlerOpenDialog}
      />
      {
          <Dialog
            open={addDialog}
            onClose={() => setDialog(false)}
          >
            <DialogTalentMobile
              resetModal={() => {
                setDialog(false);
              }}
              machine= {machineAssigned}
              item = {talentAssigned}
              listOper={listOperators}
              assignOperator={assignOperator}
            />
          </Dialog>
        }
    </BoxMainMobile>
  );
};

export default TalentMobile;