import React, { useState, useContext } from "react";
import "./App.css";
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from "apollo-link-context";
import styled from '@emotion/styled'
import { HttpLink } from "apollo-boost";
import { Box, useMediaQuery } from "@mui/material";
import { ApolloProvider } from "@apollo/react-hooks";
import {
  AuthContext,
  UserContextProvider,
  useAuth,
  UserContext,
} from "./context/auth";
import { DndProvider } from "react-dnd";
import Login from "./Containers/Login";
import Dashboard from "./Containers/Dashboard";
import Stops from "./Containers/Stops";
import Talent from "./Containers/Talent";
import Profile from "./Containers/Profile";
import Notifications from "./Containers/Notifications";
import NotificationDetail from "./Containers/NotificationDetail";
import { ApolloLink } from "apollo-link";
import { SnackbarProvider } from "notistack";
import jwt_decode from "jwt-decode";
import SuperiorMenu from "../src/Components/ui/SuperiorMenu";
import BottomMenu from "../src/Components/ui/BottomMenu";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate 
} from "react-router-dom";
import { TouchBackend } from "react-dnd-touch-backend";
import { fetchToken } from './firebase';

const cache = new InMemoryCache({
  freezeResults: true,
});
const link = new HttpLink({
  uri:"https://ixhfflgol6.execute-api.us-east-1.amazonaws.com/dev/api/graphql",
});

const isTokenValid = () => {
  const storedTokenObject = localStorage.getItem("tokens");
  const decodedTokensObject =
    storedTokenObject && JSON.parse(storedTokenObject);
  const decoded = decodedTokensObject && jwt_decode(decodedTokensObject.token);

  return decodedTokensObject && decoded && Date.now() < decoded.exp * 1000;
};

const authLink = setContext((_, { headers }) => {
  const storedTokenObject = localStorage.getItem("tokens");
  const decodedTokensObject =
    storedTokenObject && JSON.parse(storedTokenObject);

  if (
    decodedTokensObject &&
    Date.now() < jwt_decode(decodedTokensObject.token).exp
  ) {
    return {
      headers,
    };
  }

  const token = decodedTokensObject && decodedTokensObject.token;

  return {
    headers: {
      ...headers,
      Authorization: token ? `JWT ${token}` : "",
    },
  };
});

const links = ApolloLink.from([authLink, link]);

const client = new ApolloClient({
  cache: cache,
  link: links,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
  assumeImmutableResults: true,
});

const PrivateRoute = ({ children }) => {
  const authed = isTokenValid() // isauth() returns true or false based on localStorage
  
  return authed ? children : <Navigate to="/logout" />;
}
const Logout = () => {
  const { setUserData } = useContext(UserContext);
  const { setAuthTokens } = useAuth();
  setAuthTokens();
  setUserData();
  localStorage.removeItem("tokens");
  return <Navigate to="/login" noThrow replace />;
};

const BoxMain = styled(Box)(({ theme }) => {
  return {
    backgroundColor: "#F8F9F9",
    marginTop: "0px",
    "@media screen and  (min-width: 600px)": {
      marginTop: "64px",
    },
  };
});

const App = () =>{
  const tokens = localStorage.getItem("tokens");
  const existingTokens = tokens !== undefined ? tokens : "";
  const [authTokens, setAuthTokens] = useState(existingTokens);
  fetchToken();
  const setTokens = (data) => {
    if (!data) {
      localStorage.removeItem("tokens");
      setAuthTokens();
    } else {
      localStorage.setItem("tokens", JSON.stringify(data));
      setAuthTokens(data);
    }
  };

  const matches = useMediaQuery('(min-width:600px)');

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <ApolloProvider client={client}>
      <UserContextProvider>
        <DndProvider backend={TouchBackend}>
          <SnackbarProvider>
            <BoxMain>
              <BrowserRouter>
                {matches && <SuperiorMenu />} 
                <Routes>
                  <Route  key="home" path="/"
                    element={
                      <PrivateRoute>
                        <Dashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route exact key="login" path="/login" element={<Login />}/>
                  <Route exact path="/logout" element={<Logout/>}/>
                  <Route path="*"
                    element={
                      <PrivateRoute>
                        <Dashboard />
                      </PrivateRoute>
                    }
                  />
                  <Route key="stops" path="/capturer/stops" element={<Stops />} />
                  <Route key="talent" path="/capturer/talent" element={<Talent />} />
                  <Route key="profile" path="/profile" element={<Profile />} />
                  <Route key="notifications" path="/notifications" element={<Notifications />} />
                  <Route key="notificationDetails" path="/notification/details" element={<NotificationDetail />} />
                </Routes>
                {!matches && <BottomMenu />}
              </BrowserRouter>
            </BoxMain>
          </SnackbarProvider>
        </DndProvider>
        </UserContextProvider>
      </ApolloProvider>
    </AuthContext.Provider>
  );
}

export default App;
