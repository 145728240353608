import React, { useState } from "react";
import StopsDesk from "../Components/Stops";
import CapturerMobile from "../Components/CapturerMobile";
import moment from "moment-timezone";
import { CircularProgress, useMediaQuery } from "@mui/material";
import { GET_MACHINES, GET_USERS, GET_STOP_CAUSES, GET_SHIFTS } from "./Dashboard.queries";
import { useQuery } from "@apollo/client";
import styled from '@emotion/styled';

let endDate = moment.utc();
let startDate = moment.utc().subtract(7, "days");
startDate.hour(0);
startDate.minute(0);
startDate.second(0);
startDate.millisecond(0);

const LoadingProgress = styled(CircularProgress)(({ theme }) => {
  return {
    position: "absolute",
    left: "50%",
    top: "50%",
  };
});

const Stops = () => {
  const [SelectEdndDate, setEndDate] = useState(endDate);
  const [SelectedStartDate, setStartDate] = useState(startDate);
  const [SelectedItemMachine, setSelectedItemMachine] = useState();
  const [listMachines, setListMachines] = useState([]);
  const [listOperators, setListOperators] = useState([]);
  const [listStopCauses, setListStopCauses] = useState([]);
  const [listShifts, setListShifts] = useState([]);

  const { loading: loadingMachines } = useQuery(GET_MACHINES, {
    onCompleted: (data) => {
      setListMachines(data.getOwnCompanies[0].machines.sort(function(a,b){
        return a.name.localeCompare(b.name);
      }));
    }
  });

  const { loading: loadingOperators } = useQuery(GET_USERS, {
    onCompleted: (data) => {
      setListOperators(data.searchUsers.sort(function(a,b){
        return (a.firstName + " " + a.lastName).localeCompare((b.firstName + " " + b.lastName));
      }));
    },
    variables: {"input": ""}
  });

  const { loading: loadingShifts } = useQuery(GET_SHIFTS, {
    onCompleted: (data) => {
      setListShifts(data.getShiftTypesByCompany.sort(function(a,b){
        return a.name.localeCompare(b.name);
      }));
    },
    variables: {"companyId": "1"}
  });

  const { loading: loadingStopCauses } = useQuery(GET_STOP_CAUSES, {
    onCompleted: (data) => {
      setListStopCauses(data.getStopCauses.sort(function(a,b){
        return a.name.localeCompare(b.name);
      }));
    }
  });

  const handleItemClickMachine = (event, item) => {
    if (SelectedItemMachine)
    {
      if (SelectedItemMachine.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemMachine();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemMachine(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemMachine(item);
    }
  };

  const [SelectedItemShift, setSelectedItemShift] = useState();

  const handleItemClickShift = (event, item) => {
    if (SelectedItemShift)
    {
      if (SelectedItemShift.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemShift();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemShift(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemShift(item);
    }
  };


  const [SelectedItemOperator, setSelectedItemOperator] = useState();

  const stopsData = [
    {
      id: 1,
      order: 126,
      date: "07 Mar 22",
      place: "Mantenimiento",
      cause: "Falla mecánica",
      responsible: "Eduardo Regalado",
      isExpanded: false,
    },
    {
      id: 2,
      order: 126,
      date: "07 Mar 22",
      place: "Mantenimiento",
      cause: "Falla mecánica",
      responsible: "Eduardo Regalado",
      isExpanded: false,
    },
    {
      id: 3,
      order: 126,
      date: "07 Mar 22",
      place: "Mantenimiento",
      cause: "Falla mecánica",
      responsible: "Eduardo Regalado",
      isExpanded: false,
    },
    {
      id: 4,
      order: 126,
      date: "07 Mar 22",
      place: "Mantenimiento",
      cause: "Falla mecánica",
      responsible: "Eduardo Regalado",
      isExpanded: false,
    },
    {
      id: 5,
      order: 126,
      date: "07 Mar 22",
      place: "Mantenimiento",
      cause: "Falla mecánica",
      responsible: "Eduardo Regalado",
      isExpanded: false,
    },
    {
      id: 6,
      order: 126,
      date: "07 Mar 22",
      place: "Mantenimiento",
      cause: "Falla mecánica",
      responsible: "Eduardo Regalado",
      isExpanded: false,
    },
    {
      id: 7,
      order: 126,
      date: "07 Mar 22",
      place: "Mantenimiento",
      cause: "Falla mecánica",
      responsible: "Eduardo Regalado",
      isExpanded: false,
    },
    {
      id: 8,
      order: 126,
      date: "07 Mar 22",
      place: "Mantenimiento",
      cause: "Falla mecánica",
      responsible: "Eduardo Regalado",
      isExpanded: false,
    },
  ];

  const Data = {
    series: [{
      data: [85, 100, 85, 95, 100, 80, 85, 100]
    }],
    options: {
      chart: { 
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      states: {
        hover: {
          filter: {
            type: "none",
          }
        },
        active: {
          filter: {
            type: "none",
          }
        }
      },
      title: {
        text: "Utilización de talento",
        margin: 0,
        offsetX: -10,
        align: "left",
        style: {
          fontSize: "15px",
          color: "#3D4247",
          fontFamily: "Muli",
          fontWeight: 600,
        }
      },
      subtitle: {
        text: startDate.format("DD MMM YY") + " - " + endDate.format("DD MMM YY"),
        align: "left",
        margin: 0,
        offsetX: -10,
        offsetY: 20,
        style: {
          fontSize: "12px",
          color: "#3D4247",
          fontFamily: "Muli",
        },
      },
      colors: ["#336EF4"],
      plotOptions: {
        bar: {
          columnWidth: '60%',
          distributed: true,
        },
      },
      tooltip: {
        enabled: false,
      },
      dataLabels: { 
        enabled: false,
      },
      legend: { show: false },
      yaxis: {
        tickAmount: 4,
        min: 0,
        max: 100,
        labels: {
          formatter: (value) => value.toFixed(0) +'%',
        },
      },
      xaxis: {
        categories: ['Juan', 'Pepe', 'Miguel', 'Eduardo', 'José', 'Ricardo', 'Carlos', 'Adolfo'],
        labels: {
          rotate: -30,
          rotateAlways: true,
          style: {
            colors: ["#3D4247"],
            fontSize: '12px',
          },
        }
      } 
    }
  };

  const handleItemClickOperator = (event, item) => {
    if (SelectedItemOperator)
    {
      if (SelectedItemOperator.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemOperator();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemOperator(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemOperator(item);
    }
  };


  const [SelectedItemStop, setSelectedItemStop] = useState();

  const listStops = [
  ]

  const handleItemClickStop = (event, item) => {
    if (SelectedItemStop)
    {
      if (SelectedItemStop.id === item.id)
      {
        item.isSelected = false;
        setSelectedItemStop();
      }
      else
      {
        item.isSelected = true;
        setSelectedItemStop(item);
      }
    }
    else
    {
      item.isSelected = true;
      setSelectedItemStop(item);
    }
  };

  const handleChangeDate = (date) => {
    startDate = moment(date[0]);
    startDate.hour(0);
    startDate.minute(0);
    startDate.second(0);
    startDate.millisecond(0);

    endDate = moment(date[1]);
    endDate.hour(23);
    endDate.minute(59);
    endDate.second(59);
    setEndDate(endDate);
    setStartDate(startDate);
  };

  const chartDataMobileJS = {
    labels: ["Sin Captura", "Herramientas", "Material", "Mantenimiento", "Otros"],
    datasets: [
      {
        label: "Estado",
        data: [20, 20, 11, 11, 6],
        backgroundColor: ["#EE5840", "#9E5CB9", "#66CCFF", "#41BFB4", "#FDAB3D"],
        borderColor: ["#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF", "#FFFFFF"],
        borderWidth: 1,
        cutout: "85%",
        offset: 2,
      }
    ],
  };

  const doughnutLabelsLine = {
    id: "doughnutLabelsLine",
    afterDraw(chart, args, options) {
      const {ctx, chartArea: { width, height }} = chart;
  
      let total = 0;
  
      chart.data.datasets[0].data.forEach((value, idx) => {
        total += value;
      });
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
  
          const halfWidth = width / 2;
          const halfHeight = height / 2;
  
          const xLine = x >= halfWidth ? x + 20 : x - 20;
          const yLine = y >= halfHeight ? y + 20 : y - 20;
          const extraLine = x >= halfWidth ? (width/5) : - (width/5);
  
          ctx.beginPath();
          ctx.moveTo(x,y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = "#3D4247";
          ctx.stroke();
  
          ctx.font = "1vw Muli";
          const textXPosition = x >= halfWidth ? "right" : "left";
          ctx.textAlign = textXPosition;
          ctx.textBaseline = "bottom";
          ctx.fillStyle = "#3D4247";
          ctx.fillText(chart.data.labels[index], xLine + extraLine, yLine + 18);
          ctx.font = "1vw Muli";
          ctx.textAlign = textXPosition;
          ctx.fillText(((chart.data.datasets[i].data[index]/total) * 100).toFixed(0) + "% (" + chart.data.datasets[i].data[index] + " Hrs.)", xLine + extraLine, yLine - 5);
          
          ctx.font = "1.5vw Muli";
          ctx.textAlign = "center";
          ctx.fillText(total, halfWidth+30 , halfHeight+20);
          ctx.fillText("Hrs. Totales", halfWidth+30, halfHeight+45);
          ctx.font = "1vw Muli";
          ctx.fillText("Encendido sin", halfWidth+30, halfHeight+60);
          ctx.fillText("producir", halfWidth+30, halfHeight+75);
        });
      });
    }
  };

  const doughnutLabelsLineMobile = {
    id: "doughnutLabelsLine",
    afterDraw(chart, args, options) {
      const {ctx, chartArea: { width, height }} = chart;
  
      let total = 0;
  
      chart.data.datasets[0].data.forEach((value, idx) => {
        total += value;
      });
      chart.data.datasets.forEach((dataset, i) => {
        chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
          const { x, y } = datapoint.tooltipPosition();
  
          const halfWidth = width / 2;
          const halfHeight = height / 2;
  
          const xLine = x >= halfWidth ? x + 20 : x - 20;
          const yLine = y >= halfHeight ? y + 20 : y - 20;
          const extraLine = x >= halfWidth ? (width/5) : - (width/5);

          ctx.beginPath();
          ctx.moveTo(x,y);
          ctx.lineTo(xLine, yLine);
          ctx.lineTo(xLine + extraLine, yLine);
          ctx.strokeStyle = "#3D4247";
          ctx.stroke();
  
          ctx.font = "2vw Muli";
          const textXPosition = x >= halfWidth ? "right" : "left";
          ctx.textAlign = textXPosition;
          ctx.textBaseline = "bottom";
          ctx.fillStyle = "#3D4247";
          ctx.fillText(chart.data.labels[index], xLine + extraLine, yLine + 15);
          ctx.font = "2vw Muli";
          ctx.textAlign = textXPosition;
          ctx.fillText(((chart.data.datasets[i].data[index]/total) * 100).toFixed(0) + "% (" + chart.data.datasets[i].data[index] + " Hrs.)", xLine + extraLine, yLine - 5);
          ctx.font = "3.3vw Muli";
          ctx.textAlign = "center";
          ctx.fillText(total, halfWidth+30 , halfHeight+20);
          ctx.fillText("Hrs. Totales", halfWidth+30, halfHeight+42);
          ctx.font = "2vw Muli";
          ctx.fillText("Encendido sin", halfWidth+30, halfHeight+58);
          ctx.fillText("producir", halfWidth+30, halfHeight+68);
        });
      });
    }
  };

  const optionsJS = {
    layout: {
      padding: 30,
    },
    responsive: true,
    aspectRatio: 1.8,
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  };

  const title = "PREN-010";
  const subtitle = startDate.format("DD MMM YY") + " - " + endDate.format("DD MMM YY");

  const configJS = {
    type: "doughnut",
    data: chartDataMobileJS,
    options: optionsJS,
    plugins: [doughnutLabelsLineMobile],
  };

  const config = {
    type: "doughnut",
    data: chartDataMobileJS,
    options: optionsJS,
    plugins: [doughnutLabelsLine],
  };

  const matches = useMediaQuery('(min-width:600px)');

  if (loadingMachines || loadingOperators || loadingStopCauses || loadingShifts)
    return <LoadingProgress />;

  return (
    <>
      {
        matches && <StopsDesk 
          listMachines={listMachines} 
          handleItemClickMachine = {handleItemClickMachine} 
          SelectedItemMachine={SelectedItemMachine} 
        
          listShift={listShifts} 
          handleItemClickShift = {handleItemClickShift} 
          SelectedItemShift={SelectedItemShift} 
        
          listOperators={listOperators} 
          handleItemClickOperator = {handleItemClickOperator} 
          SelectedItemOperator={SelectedItemOperator} 
        
          listStops={listStops} 
          handleItemClickStop = {handleItemClickStop} 
          SelectedItemStop={SelectedItemStop} 
        
          startDate={SelectedStartDate.format("DD MMM YY")}
          endDate={SelectEdndDate.format("DD MMM YY")}
          handleChangeDate={handleChangeDate}
          
          title={title}
          config={config}
          stopsData={stopsData}
          listStopCauses={listStopCauses}
        />
      }
      {
        !matches && <CapturerMobile
          listMachines={listMachines} 
          handleItemClickMachine = {handleItemClickMachine} 
          SelectedItemMachine={SelectedItemMachine} 
        
          listShift={listShifts} 
          handleItemClickShift = {handleItemClickShift} 
          SelectedItemShift={SelectedItemShift} 
        
          listOperators={listOperators} 
          handleItemClickOperator = {handleItemClickOperator} 
          SelectedItemOperator={SelectedItemOperator} 
        
          listStops={listStops} 
          handleItemClickStop = {handleItemClickStop} 
          SelectedItemStop={SelectedItemStop} 
        
          startDate={SelectedStartDate.format("DD MMM YY")}
          endDate={SelectEdndDate.format("DD MMM YY")}
          handleChangeDate={handleChangeDate}
          
          configJS={configJS}
          title={title}
          subtitle={subtitle}
          barOptions={Data.options}
          barSeries={Data.series}
          isStopSelected={true}
          stopsData={stopsData}
          listStopCauses={listStopCauses}
        />
      }
    </>
  )

}

export default Stops;