import React, { useState } from "react";
import {
    Popover,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Badge,
} from "@mui/material";
import Notifications from "@mui/icons-material/Notifications";
import styled from '@emotion/styled';
import { useTranslation } from "react-i18next";

const MyIconButton = styled(IconButton)(({ theme }) => {
    return {
        padding: "0px",
    };
});

const MyNotificationsIcon = styled(Notifications)(({ theme }) => {
    return {
        color: "#8a8a8a",
        height: "25px",
        width: "25px",
    };
});

const MyList = styled(List)(({ theme }) => {
    return {
        backgroundColor: theme.palette.surface.fordGray,
        color: theme.palette.text.white,
    };
});

const MyListItem = styled(ListItem)(({ theme }) => {
    return {
        backgroundColor: theme.palette.surface.fordGray,
        color: theme.palette.text.white,
    };
});

const NotificationPopOver = ({ notifications = [] }) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return [
        <MyIconButton onClick={handleClick}>
            <Badge variant="dot" color="error" invisible={!notifications.length}>
                <MyNotificationsIcon />
            </Badge>
        </MyIconButton>,
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
        >
            <MyList>
                {notifications.map(({ id, text, read }) => (
                    <ListItem key={`notification-text-${id}`}>
                        <ListItemText>{text}</ListItemText>
                    </ListItem>
                ))}
                {!notifications.length && (
                    <MyListItem key={`notification-text-${id}`}>
                        <ListItemText>{t("NoNewNotifications")}</ListItemText>
                    </MyListItem>
                )}
            </MyList>
        </Popover>
    ];
};

export default NotificationPopOver;