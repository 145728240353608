import {useContext, React} from "react";
import { Link, useLocation } from "react-router-dom";
import { AppBar } from "@mui/material";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled';
import DashboardIcon  from "@mui/icons-material/Home";
import CapturerIcon from '@mui/icons-material/AlignHorizontalLeft';
import ConnectionsImg from "../../images/ERP.svg";
import Notifications from "@mui/icons-material/Notifications";
import PersonIcon from '@mui/icons-material/Person';
import { UserContext } from "../../context/auth";

const MyAppBar = styled(AppBar)(({ theme }) => {
    return {
      top: "auto", 
      bottom: 0,
    };
  });

const DivButtons = styled("div")(({ theme }) => {
  return {
    "display": "flex",
    "flex-wrap": "wrap",
    "justify-content": "space-around",
    "align-items": "center",
    height: "57px",
    border: "0.3px solid #979797",
    backgroundColor: theme.palette.surface.white,
  };
});

const MySvgImg = styled("img")(({ theme }) => {
  return {
    height: "25px",
    width: "25px",
  }
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const BottomMenu = () => {
  const location = useLocation();
  const { userData } = useContext(UserContext);
  if (!userData) {
      return null;
  }
  let color_dash = location.pathname.includes("dashboard") ?  "#336EF4" : "#9C9C9C";
  let color_capturer = location.pathname.includes("capturer") ?  "#336EF4" : "#9C9C9C";
  let color_notifications = location.pathname.includes("notifications") ?  "#336EF4" : "#9C9C9C";
  let color_profile = location.pathname.includes("profile") ?  "#336EF4" : "#9C9C9C";

  return (
    <>
      <MyAppBar position="fixed">
        <DivButtons>
          <Link to="/dashboard" style={{textDecoration: "none"}}>
            <DashboardIcon style={{color: color_dash}}/>
          </Link>
          <Link to="/capturer/stops" style={{textDecoration: "none"}}>
              <CapturerIcon style={{color: color_capturer}}/>
          </Link>
          <CustomWidthTooltip title="Esta opción no esta disponible en esta versión." 
            placement="top"
            >
            <span>
              <Link to="/connections" style={{textDecoration: "none"}} onClick={event => event.preventDefault()}>
                <MySvgImg src={ConnectionsImg} />
              </Link>
            </span>
          </CustomWidthTooltip>
          <Link to="/notifications" style={{textDecoration: "none"}}>
              <Notifications style={{color: color_notifications}}/>
          </Link>
          <Link to="/profile" style={{textDecoration: "none"}}>
              <PersonIcon style={{color: color_profile}}/>
          </Link>
        </DivButtons>
      </MyAppBar>
    </>
  );
}

export default BottomMenu;