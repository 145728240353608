import { gql } from "apollo-boost";

export const GET_MACHINES = gql`
    query GetOwnCompanies{
        getOwnCompanies {
            id
            name
            users {
                id
                user {
                    id
                    username
                    firstName
                    lastName
                }
            }
            machines {
                id
                name
            }
        }
    }
`;

export const GET_USERS = gql`
    query SearchUsers($input: String!) {
        searchUsers(input: $input) {
            id
            username
            firstName
            lastName
        }
    }
`;

export const GET_SHIFTS = gql`
    query GetShiftTypesByCompany($companyId: ID!) {
        getShiftTypesByCompany(companyId: $companyId) {
            id
            name
        }
    }
`;

export const GET_STOP_CAUSES = gql`
query GetStopCauses{
    getStopCauses {
        id
        name
    }
}
`;
