import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { 
    AppBar, 
    Toolbar, 
    Typography,
    Grid
} from "@mui/material";
import styled from '@emotion/styled';

const MyAppBar = styled(AppBar)(({ theme }) => {
  return {
    direction: "row",
    background: "white",
    "margin-top": "64px",
    "z-index": 1
  }
});

const SubMenu = ({ optionsList = [] }) => {
  const { t } = useTranslation();

  const NavLink = (props) => (
    <Link
            {...props}
            getProps={({ isPartiallyCurrent }) => {
                return {
                    style: {
                        opacity: isPartiallyCurrent ? "1" : "0.5",
                        color: "transparent",
                        marginRight: "18px",
                    },
                };
            }}
        />
  );

  const ItemText = (props) => {
    let opt = props.item;
    const location = useLocation();

    let colorTxt = location.pathname.includes(opt.path) ? "#336EF4" : "#9A9DA0";
    let MyText = styled(Typography)(({theme }) => {
      return {
        fontSize: "0.7rem",
        color: colorTxt,
        lineHeight: "26px",
        letterSpacing: "0",
        paddingRight: "18px",
        textDecoration: "none",
        "@media screen and (min-width: 687px)": {
            fontSize: "1rem",
        },
      };
    })
  
    return <MyText>{t(opt.text)}</MyText>;
  };

  return (
  <MyAppBar>
    <Toolbar>
      <Grid container>
        <Grid item container xs={12} style={{ marginTop: "12px"}} alignItems="center">
        {optionsList.map((opt) => (
              <NavLink key={opt.id} to={opt.path} style={{textDecoration: "none"}}>
                <ItemText item={opt}></ItemText>
              </NavLink>
            ))}
        </Grid>
      </Grid>
    </Toolbar>
  </MyAppBar>

  );
};

export default SubMenu;